import { Button } from "@material-tailwind/react";

export default function CompletedFormComponent({ goBack }) {
  return (
    <div
      className="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular
            flex flex-col text-left font mx-10 md:mx-44 gap-4 font-light"
    >
      <p className="font-bold text-xl">
        <b>¡Enhorabuena! El formulario ha sido</b>{" "}
        <span className="text-[#f56d0d]">enviado correctamente</span>
      </p>
      <p className="">
        Nos pondremos en contacto contigo en la mayor brevedad posible para
        tramitar la gestión.
      </p>
      <p>¡Muchas gracias!</p>

      <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
        <Button
          className="form-button-before capitalize flex justify-between items-center text-lg font-normal"
          onClick={goBack}
        >
          <svg
            className="Icon_awesome-long-arrow-alt-ri color-arrow-before"
            viewBox="0 10.258 31.5 15.484"
          >
            <g transform="scale(-1, 1) translate(-32, 0)">
              <path
                id="Icon_awesome-long-arrow-alt-ri"
                className="color-arrow-before"
                d="M 22.07397651672363 15.1875 L 0.84375 15.1875 C 0.3777890503406525 15.1875 0 15.56528949737549 0 16.03125 L 0 19.96875 C 0 20.43471145629883 0.3777890503406525 20.8125 0.84375 20.8125 L 22.07397651672363 20.8125 L 22.07397651672363 24.05102348327637 C 22.07397651672363 25.55444526672363 23.89162445068359 26.30735206604004 24.95475006103516 25.24429702758789 L 31.00577354431152 19.19327354431152 C 31.6648120880127 18.53423500061035 31.6648120880127 17.46576499938965 31.00577354431152 16.80679702758789 L 24.95475006103516 10.75577354431152 C 23.89169502258301 9.692718505859375 22.07397651672363 10.44562530517578 22.07397651672363 11.94904708862305 L 22.07397651672363 15.1875 Z"
              />
            </g>
          </svg>
          Volver al inicio
        </Button>
      </div>
    </div>
  );
}
