export default function WheelSteel() {
  return (
    <svg
      className="bg-orange-800 fill-orange-100 rounded-full p-2 size-14"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g stroke="none" stroke-width="1">
          <g>
            <path
              d="M7.99899041,16 C3.58808682,16 0,12.4108272 0,8 C0,3.58917278 3.58808682,0 7.99899041,0 C12.4109036,0 16,3.58917278 16,8 C16,12.4108272 12.4109036,16 7.99899041,16 L7.99899041,16 Z M8,2 C4.69083225,2 2,4.69151671 2,8 C2,11.3084833 4.69180754,14 8,14 C11.3081925,14 14,11.3084833 14,8 C14,4.69151671 11.3091678,2 8,2 L8,2 Z"
              class="si-glyph-fill"
            ></path>
            <path
              d="M7.992,6 C5.676,6 3.894,6.797 3.031,8.346 C3.068,8.819 3.185,9.274 3.367,9.698 C4.588,9.046 6.918,11.528 6.185,12.633 C6.765,12.837 7.326,12.988 7.982,12.988 C8.627,12.988 9.299,12.87 9.869,12.673 C9.135,11.568 11.394,9.138 12.623,9.804 C12.811,9.366 12.932,8.898 12.967,8.408 C12.095,6.875 10.293,6 7.992,6 L7.992,6 Z M8.002,9.156 C7.377,9.156 6.875,8.646 6.875,8.015 C6.875,7.384 7.377,6.874 8.002,6.874 C8.621,6.874 9.125,7.384 9.125,8.015 C9.125,8.646 8.621,9.156 8.002,9.156 L8.002,9.156 Z"
              class="si-glyph-fill"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
