import React, { useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import FileErrorSize from "./FileError";
import FileErrorExtension from "./FileErrorExtension";

const FileInputComponent = ({ label, name, onChange, isValid }) => {
  const [fileName, setFileName] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const inputClasses = `relative rounded-md p-4 flex justify-center items-center cursor-pointer input-file-form text-xs
                ${
                  isValid === undefined
                    ? ""
                    : isValid === true && isValid !== 1 && isValid !== 2
                    ? "valid-bg"
                    : "invalid-bg"
                }`;

  const openFileInput = () => {
    const fileInput = document.getElementById(`${name}FileInput`);
    fileInput.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("");
    }
    onChange(event);
  };

  const handleRemoveFile = (event) => {
    event.stopPropagation();
    setFileName("");
    const fileInput = document.getElementById(`${name}FileInput`);
    fileInput.value = "";
    onChange({ target: { name, files: [] } }, true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsDragging(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      const fileInput = document.getElementById(`${name}FileInput`);
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      fileInput.files = dataTransfer.files;
      onChange({ target: { name, files: dataTransfer.files } });
    }
  };

  return (
    <div className="flex flex-col space-y-1 text-left">
      <label
        htmlFor={`${name}FileInput`}
        className="block text-xs font-bold mb-1 cursor-pointer color-blue-custom-text"
      >
        {label}
      </label>
      {isValid === 2 && <FileErrorSize />}
      {isValid === 1 && <FileErrorExtension />}
      <div
        className={inputClasses}
        onClick={openFileInput}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <GrAttachment className="h-5 w-5 color-blue-custom-text absolute left-3" />
        {fileName ? (
          <div className="flex justify-between w-full">
            <span className="text-center pl-6">{fileName}</span>
            <AiOutlineClose
              className="h-5 w-5 color-red-custom-text cursor-pointer"
              onClick={handleRemoveFile}
            />
          </div>
        ) : isDragging ? (
          <span className="text-center text-herrera-orange">
            ¡Suelta el archivo aquí!
          </span>
        ) : (
          <span className="text-center">Subir del ordenador</span>
        )}
        <input
          type="file"
          id={`${name}FileInput`}
          name={name}
          className="sr-only"
          onChange={handleFileChange}
        />
      </div>
      <p className="mt-2 text-xs color-blue-custom-text">O arrastra aquí</p>
    </div>
  );
};

export default FileInputComponent;
