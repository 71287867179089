import React from "react";
import { Constant } from "./constants";
import { porcentajeDepreciacion } from "./calculations";

export default function mailBody(
  vehicleForm,
  buyerForm,
  sellerForm,
  priceDetails
) {
  return `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>mail body</title>
    <style>
      body {
        display: flex;
        flex-direction: column;
        width: max-content;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      }
      main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2em;
      }
      p {
        color: aliceblue;
        font-size: small;
        text-align: center;
      }
      header {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;
        width: 700px;
        height: 200px;
        background-color: rgb(255, 115, 0);
        padding: 2em;
        margin: auto;
        margin-bottom: 2em;
        border-bottom-left-radius: 2em;
        border-bottom-right-radius: 2em;
      }
      section {
        width: 600px;
        height: auto;
        padding: 1em;
        background-color: rgb(235, 235, 235);
        margin-bottom: 1em;
        margin: auto;
        margin-top: 2em;
      }
      h1 {
        font-size: 3em;
        color: aliceblue;
        margin: auto;
        padding: 0;
      }
      h3 {
        font-size: x-large;
        margin: 0;
      }
      h4 {
        color: rgb(255, 230, 210);
        font-size: larger;
        text-align: center;
        width: 75%;
        margin: auto;
      }
      table {
        margin-top: 1em;
        width: 100%;
      }
      th,
      td {
        font-size: large;
        color: rgb(85, 85, 85);
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
      }
      th {
        font-size: 20px;
        text-align: left;
      }
    </style>
  </head>
  <body>
    <div>
      <header style="flex-direction: column">
        <div style="display: flex; width: 100%">
          <div style="display: flex; justify-content: left">
            <a href="https://herreraconsultores.net/">
              <img src="cid:imagen1" style="width: 180px" alt="Logo Herrera">
            </a>
          </div>
        </div>
        <div style="display: flex; width: 100%"><h1>¡HOLA!</h1></div>
        <div style="display: flex; width: 100%">
          <h4>
            Aquí tienes un resumen de la gestión del vehículo a traspasar, ya
            puedes confirmar que todos los datos son correctos
          </h4>
        </div>
      </header>
      <main>
        <section>
          <h3><strong>Datos del vehículo</strong></h3>
          <table>
            <tbody>
              <tr>
                <th>Marca</th>
                <th>Combustible</th>
                <th>Cilindrada del vehículo</th>
              </tr>
              <tr>
                <td>${vehicleForm.brand.value}</td>
                <td>${vehicleForm.fuel.value}</td>
                <td>${vehicleForm.vehicleCC.value + " cc"}</td>
              </tr>
              <tr>
                <th>Potencia fiscal CV</th>
                <th>Modelo</th>
                <th>Fecha matriculación</th>
              </tr>
              <tr>
                <td>${vehicleForm.fiscalStrenght.value + " CV"}</td>
                <td>${vehicleForm.vehicleModel.value}</td>
                <td>${vehicleForm.matriculationDate.value}</td>
              </tr>
              <tr>
                <th>Fecha de venta</th>
                <th>Precio de venta</th>
                <th>Provincia del comprador</th>
              </tr>
              <tr>
                <td>${vehicleForm.saleDate.value}</td>
                <td>${vehicleForm.price.value + " €"}</td>
                <td>${vehicleForm.buyerProvince.value}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h3><strong>Datos del comprador</strong></h3>
          <table>
            <tbody>
              <tr>
                <th>DNI</th>
                <th>Nombre</th>
                <th>Sexo</th>
              </tr>
              <tr>
                <td>${buyerForm.buyerDni.value}</td>
                <td>${buyerForm.buyerName.value}</td>
                <td>${buyerForm.buyerGender.value}</td>
              </tr>
              <tr>
                <th>Apellido 1</th>
                <th>Apellido 2</th>
                <th>Fecha nacimiento</th>
              </tr>
              <tr>
                <td>${buyerForm.buyerLastName1.value}</td>
                <td>${buyerForm.buyerLastName2.value}</td>
                <td>${buyerForm.buyerBirthDate.value}</td>
              </tr>
              <tr>
                <th>Correo Electrónico</th>
                <th>Teléfono</th>
              </tr>
              <tr>
                <td>${buyerForm.buyerEmail.value}</td>
                <td>${buyerForm.buyerPhone.value}</td>
              </tr>
              <tr>
                <th>Dirección</th>
              </tr>
              <tr>
                <td>${buyerForm.buyerAddress.value}</td>
              </tr>
              <tr>
                <th>Localidad</th>
                <th>Provincia</th>
                <th>Código Postal</th>
              </tr>
              <tr>
                <td>${buyerForm.buyerCity.value}</td>
                <td>${buyerForm.buyerProvince.value}</td>
                <td>${buyerForm.buyerPostalCode.value}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h3><strong>Datos del vendedor</strong></h3>
          <table>
            <tbody>
              <tr>
                <th>DNI</th>
                <th>Nombre</th>
                <th>Sexo</th>
              </tr>
              <tr>
                <td>${sellerForm.sellerDni.value}</td>
                <td>${sellerForm.sellerName.value}</td>
                <td>${sellerForm.sellerGender.value}</td>
              </tr>
              <tr>
                <th>Apellido 1</th>
                <th>Apellido 2</th>
                <th>Fecha nacimiento</th>
              </tr>
              <tr>
                <td>${sellerForm.sellerLastName1.value}</td>
                <td>${sellerForm.sellerLastName2.value}</td>
                <td>${sellerForm.sellerBirthDate.value}</td>
              </tr>
              <tr>
                <th>Correo Electrónico</th>
                <th>Teléfono</th>
              </tr>
              <tr>
                <td>${sellerForm.sellerEmail.value}</td>
                <td>${sellerForm.sellerPhone.value}</td>
              </tr>
              <tr>
                <th>Dirección</th>
              </tr>
              <tr>
                <td>${sellerForm.sellerAddress.value}</td>
              </tr>
              <tr>
                <th>Localidad</th>
                <th>Provincia</th>
                <th>Código Postal</th>
              </tr>
              <tr>
                <td>${sellerForm.sellerCity.value}</td>
                <td>${sellerForm.sellerProvince.value}</td>
                <td>${sellerForm.sellerPostalCode.value}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h3><strong>Documentos</strong></h3>
          <p style="font-size: large; color: rgb(85, 85, 85)">
            Adjuntado en este correo se encuentra un archivo ZIP con todos los
            archivos subidos a la plataforma, documentación de comprador,
            vendedor, vehículo así como el contrato.
          </p>
        </section>

        ${
          priceDetails.total !== null &&
          priceDetails.total !== undefined &&
          priceDetails.total !== "--"
            ? `<div
          style="
            border: 4px solid #ffc78f;
            border-radius: 8px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            width: 300px;
            font-family: Arial, sans-serif;
            margin: auto;
            margin-top: 2em;
          "
        >
          <h2 style="color: #ff8000; font-size: 24px">Desglose de costes</h2>
          <div style="margin-bottom: 10px">
            <div
              style="font-weight: bold;"
            >
              ITP (${priceDetails.porcentajeITP} %)
              <span style="float: right">${priceDetails.valorITP + " €"}</span>
            </div>
            <div style="margin-left: 20px; font-size: 14px">
              <div>
                Valoración tablas Hacienda
                <span style="float: right">${
                  priceDetails.valoracionTablasHacienda + " €"
                }</span>
              </div>
              <div>
                Porcentaje Depreciación <span style="float: right">${
                  priceDetails.porcentajeDeprecia + " %"
                }</span>
              </div>
              <div>Valor fiscal <span style="float: right">${
                priceDetails.valorFiscal + " €"
              }</span></div>
              <div>ITP (${
                priceDetails.porcentajeITP
              } %) <span style="float: right">${
                priceDetails.valorITP + " €"
              }</span></div>
            </div>
          </div>
          <div style="margin-bottom: 10px;">
            Tasas DGT - Transferencia
            <span style="float: right">${
              priceDetails.transferenciaDGT + " €"
            }</span>
          </div>
          ${
            priceDetails.informesDGT !== null &&
            priceDetails.informesDGT !== undefined
              ? `<div style="margin-bottom: 10px;">
                  Tasas DGT - Informes
                  <span style="float: right">${
                    priceDetails.informesDGT + " €"
                  }</span>
                </div>`
              : ""
          }
          <div style="margin-bottom: 10px;">
            Poliza colegio
            <span style="float: right">${
              priceDetails.polizaColegio + " €"
            }</span>
          </div>
          <div
            style="margin-bottom: 10px;"
          >
            Trámites y mensajería
            <span style="float: right">${
              priceDetails.tramitesMensajeria + " €"
            }</span>
          </div>
          <div style="margin-bottom: 10px;">
            IVA
            <span style="float: right">${priceDetails.iva + " €"}</span>
          </div>
          <div
            style="
              padding-top: 10px;
              font-weight: bold;
            "
          >
            <div>
              Total (IVA incl.)
              <span style="float: right">${priceDetails.total + " €"}</span>
            </div>
            <div style="font-size: 12px">*Precio provisional</div>
          </div>
        </div>`
            : ""
        }
        <section>
          <p style="font-size: large; color: rgb(85, 85, 85)">
            Si encuentras algún problema con los datos no dudes en ponerte
            en contacto con nosotros en el <strong>954 81 58 46</strong>
          </p>
        </section>
        <section
          style="
            padding: 2rem;
            display: flex;
            background-color: rgb(86, 139, 255);
          "
        >
          <div>
            <a href="https://herreraconsultores.net/">
              <img
              src="https://herreraconsultores.net/wp-content/uploads/2023/08/Logotipo.png"
              style="width: 150px; margin-right: 2rem; margin-top: 2rem; margin-bottom: 2rem"
              alt="Logo de Herrera Consultores"
            />
            </a>
            <div style="display: flex; margin-left: 2rem">
              <a href=""><img src="cid:imagen2" style="width: 20px; margin-right: 1rem" alt="Facebook"></a>
              <a href="https://www.linkedin.com/company/herrera-consultores/"><img src="cid:imagen3" style="width: 20px; margin-right: 1rem" alt="Linkedin"></a>
            </div>
          </div>
          <div>
            <p>
              Herrera consultores es la responsable del tratamiento de tus
              datos, con las siguientes finalidades:
            </p>
            <p>
              En caso de haber contratado alguno de sus servicios para mantener
              la relación contractual, así como la gestión, administración,
              información, prestación y mejora del servicio.
            </p>
            <p>
              Puedes consultar los términos y condiciones en el siguiente enlace
              de "<a href="https://herreraconsultores.net/politica-privacidad/">Términos y condiciones</a>".
            </p>
          </div>
        </section>
      </main>
    </div>
  </body>
</html>`;
}
