import React from "react";

export default function TextDocumentNotice({ text, blueText }) {
  return (
    <div className="w-full md:w-3/3">
      <p className="text-notice-document">
        {text}
        <span className="color-blue-custom-text font-medium">{blueText}</span>
      </p>
    </div>
  );
}
