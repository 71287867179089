import React, { useEffect, useState } from "react";
import ButtonBefore from "./ButtonBefore";
import ButtonNext from "./ButtonNext";
import TextDocumentNotice from "./TextDocumentNotice";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import PickerWithButtonField from "./ButtonFieldProps";
import dayjs from "dayjs";
import {
  validateBirthDate,
  validateDNI,
  validateEmail,
  validateLastName,
  validateName,
  validatePhone,
  validatePostalCode,
  validateSelect,
  withoutValidation,
} from "../utils/validator";
import AddressFormFieldsSeller from "./AddressFormFieldsSeller";
import HandWithMoney from "../utils/svg/handwithmoney";
import { Constant } from "../utils/constants";

const Form3Component = ({ onNextTab, onPreviousTab, formData, onChange }) => {
  const [sellerForm, setSellerForm] = useState(formData);
  const [birthDate, setBirthDate] = useState(
    sellerForm.sellerBirthDate.value || null
  );
  const [provincia, setProvincia] = useState(undefined);
  const [datos, setDatos] = useState([]);
  const [auxData, setAuxData] = useState(false);

  const newFieldState = (value, isValid) => {
    return { value: `${value}`, valid: isValid };
  };

  const saveFormToLocalStorage = (formName, formData) => {
    localStorage.setItem(formName, JSON.stringify(formData));
  };

  useEffect(() => {
    if (sellerForm.sellerPostalCode.value !== "") {
      fetch(
        `${Constant.SERVER_URL}/api/postalCode?postalCode=${sellerForm.sellerPostalCode.value}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("Datos del municipio", data);
          setDatos(data);

          if (data.length === 1) {
            const localidad = data[0].nombre_municipio;
            const pro = data[0].nombre_provincia;
            handleChangeCity("sellerCity", localidad);
            setProvincia([pro]);
            handleChangeField("sellerProvince", pro);
          } else if (data.length === 0) {
            sellerForm.sellerPostalCode.valid = false;
            sellerForm.sellerCity.valid = undefined;
            sellerForm.sellerProvince.valid = undefined;
          }
        })
        .catch((error) => {
          console.error("Error al obtener los datos del municipio:", error);
        });
    } else if (sellerForm.sellerPostalCode.value === "") {
      handleChangeField("sellerCity", "");
      sellerForm.sellerCity.valid = false;
      handleChangeField("sellerProvince", "");
      sellerForm.sellerProvince.valid = false;
      setDatos([]);
      setProvincia();
    } else {
      setDatos([]);
      setProvincia();
    }
  }, [sellerForm.sellerPostalCode.value]);

  const handleChangeField = (fieldName, value) => {
    console.log("Cambiando de valor: ", fieldName + " a " + value);

    let isValid = undefined;

    if (validators[fieldName]) {
      if (value && value !== "") {
        const validator = validators[fieldName];
        isValid = validator(value);
        console.log("Es válido: ", isValid);
      }
    }

    onChange(fieldName, newFieldState(value, isValid)); // Guardar en formulario global

    const newFieldStateValue = newFieldState(value, isValid);

    setSellerForm((prevalue) => {
      const updatedForm = {
        ...prevalue,
        [fieldName]: newFieldStateValue,
      };

      saveFormToLocalStorage("sellerForm", updatedForm);

      return updatedForm;
    });
  };

  const validators = {
    sellerDni: validateDNI,
    sellerName: validateName,
    sellerLastName1: validateLastName,
    sellerLastName2: validateLastName,
    sellerGender: validateSelect,
    sellerEmail: validateEmail,
    sellerBirthDate: validateBirthDate,
    sellerPhone: validatePhone,
    sellerCity: validateSelect,
    sellerProvince: validateSelect,
    sellerPostalCode: validatePostalCode,
    sellerAddress: withoutValidation,
    sellerAuxAddress: withoutValidation,
    sellerAuxPostalCode: validatePostalCode,
    sellerAuxCity: validateSelect,
    sellerAuxProvince: validateSelect,
  };

  const handleChangeBirthDate = (value) => {
    setBirthDate(dayjs(value));
    handleChangeField("sellerBirthDate", dayjs(value).format("DD/MM/YYYY"));
  };

  const handleChangeCity = (fieldName, value) => {
    handleChangeField(fieldName, value);

    const dato = datos.find((dato) => dato.nombre_municipio === value);

    if (dato) {
      handleChangeField("sellerProvince", dato.nombre_provincia);
      const nuevaProvincia = dato.nombre_provincia;
      setProvincia(
        Array.isArray(nuevaProvincia) ? nuevaProvincia : [nuevaProvincia]
      );
    }
  };

  const handleChangeNumericInput = (fieldName, value) => {
    if (/^\d*$/.test(value)) {
      handleChangeField(fieldName, value);
    } else {
      return false;
    }
  };

  const handleNext = () => {
    onNextTab();
  };
  return (
    <form className="w-full">
      <div className="flex md:hidden -mt-7 w-full align-middle justify-center text-3xl text-orange-800 mb-10">
        <div className="mr-2">
          <HandWithMoney />
        </div>
        <div className="flex self-center">
          <p>Vendedor</p>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 md:mb-2">
        <div className="w-full mb-2 md:mb-0 md:w-1/3 px-3">
          <InputComponent
            type="text"
            placeholder="DNI/NIE"
            id="dniInput"
            name="dni"
            value={sellerForm.sellerDni.value}
            onChange={(e) => handleChangeField("sellerDni", e.target.value)}
            isValid={sellerForm.sellerDni.valid}
          />
        </div>
        <div className="w-full mb-2 md:mb-0 md:w-1/3 px-3">
          <InputComponent
            type="text"
            placeholder="Nombre"
            id="nameInput"
            name="name"
            value={sellerForm.sellerName.value}
            onChange={(e) => handleChangeField("sellerName", e.target.value)}
            isValid={sellerForm.sellerName.valid}
          />
        </div>
        <div className="w-full mb-2 md:mb-0 md:w-1/3 px-3">
          <SelectComponent
            placeholder="Sexo"
            options={["Hombre", "Mujer", "Prefiero no decirlo"]}
            id="genderSelect"
            name="gender"
            value={sellerForm.sellerGender.value}
            onChange={(e) => handleChangeField("sellerGender", e.target.value)}
            isValid={sellerForm.sellerGender.valid}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 md:mb-2">
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <InputComponent
            type="text"
            placeholder="Apellido 1"
            id="lastName1Input"
            name="lastName1"
            value={sellerForm.sellerLastName1.value}
            onChange={(e) =>
              handleChangeField("sellerLastName1", e.target.value)
            }
            isValid={sellerForm.sellerLastName1.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <InputComponent
            type="text"
            placeholder="Apellido 2"
            id="lastName2Input"
            name="lastName2"
            value={sellerForm.sellerLastName2.value}
            onChange={(e) =>
              handleChangeField("sellerLastName2", e.target.value)
            }
            isValid={sellerForm.sellerLastName2.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <PickerWithButtonField
            labelName="Fecha de Nacimiento"
            value={birthDate !== null ? dayjs(birthDate, "DD/MM/YYYY") : null}
            onChange={handleChangeBirthDate}
            isValid={sellerForm.sellerBirthDate.valid}
            maxDate={dayjs()}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 md:mb-2">
        <div className="w-full md:w-2/3 mb-2 md:mb-0 px-3">
          <InputComponent
            type="email"
            placeholder="Correo electrónico"
            id="emailInput"
            name="email"
            value={sellerForm.sellerEmail.value}
            onChange={(e) => handleChangeField("sellerEmail", e.target.value)}
            isValid={sellerForm.sellerEmail.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <InputComponent
            type="tel"
            placeholder="Teléfono"
            id="phoneInput"
            name="phone"
            value={sellerForm.sellerPhone.value}
            onChange={(e) =>
              handleChangeNumericInput("sellerPhone", e.target.value)
            }
            isValid={sellerForm.sellerPhone.valid}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 md:mb-2">
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <InputComponent
            type="text"
            placeholder="Código Postal"
            id="postalCodeInput"
            name="postalCode"
            value={sellerForm.sellerPostalCode.value}
            onChange={(e) =>
              handleChangeNumericInput("sellerPostalCode", e.target.value)
            }
            isValid={sellerForm.sellerPostalCode.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <SelectComponent
            placeholder="Localidad"
            options={datos
              .map((datos) => datos.nombre_municipio)
              .filter((value, index, self) => self.indexOf(value) === index)}
            id="sellerCity"
            name="sellerCity"
            value={sellerForm.sellerCity.value}
            onChange={(e) => {
              handleChangeNumericInput("sellerCity", e.target.value);
              handleChangeCity("sellerCity", e.target.value);
            }}
            isValid={sellerForm.sellerCity.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <SelectComponent
            placeholder="Provincia"
            options={provincia ? provincia : []}
            id="sellerProvince"
            name="sellerProvince"
            value={sellerForm.sellerProvince.value}
            readOnly={true}
            isValid={sellerForm.sellerProvince.valid}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-3/3 px-3 md:mb-0">
          <InputComponent
            type="text"
            placeholder="Dirección"
            id="addressInput"
            name="address"
            value={sellerForm.sellerAddress.value}
            onChange={(e) => handleChangeField("sellerAddress", e.target.value)}
            isValid={sellerForm.sellerAddress.valid}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6 px-3">
        <TextDocumentNotice text="Te recogeremos el permiso de circulación en esta dirección." />
      </div>
      <div className="flex flex-row w-full pl-1">
        <div className="pr-2">
          <InputComponent
            type="checkbox"
            id="differentModel"
            name="differentModel"
            onChange={(e) => {
              setAuxData(e.target.checked);
              if (!e.target.checked) {
                handleChangeField("sellerAuxPostalCode", "");
                handleChangeField("sellerAuxAddress", "");
              }
            }}
          />
        </div>
        <div>
          <p className="text-xs text-blue-700">Añadir una dirección de envio</p>
        </div>
      </div>
      {auxData && (
        <div
          className={`mb-4 transition-all pt-3 duration-500 ease-in-out ${
            auxData
              ? "opacity-100 animate-fade-in-and-down pb-2"
              : "display-none pb-0 opacity-0 animate-fade-out-and-up"
          }`}
        >
          <AddressFormFieldsSeller
            onChange={handleChangeField}
            sellerCity={sellerForm.sellerAuxCity}
            sellerPostalCode={sellerForm.sellerAuxPostalCode}
            sellerProvince={sellerForm.sellerAuxProvince}
            sellerAddress={sellerForm.sellerAuxAddress}
          />
        </div>
      )}
      <div className="flex flex-wrap -mx-3 mb-6 mt-6 px-3">
        <TextDocumentNotice
          text="* Si la dirección del domicilio no coincide con la que aparece en tu 
        documento de identidad, será necesario adjuntar en la pestaña documentos del formulario el Documento 
        del certificado de empadronamiento en vigor."
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between self-center md:flex-wrap -mx-3 mb-6">
        <div className="flex justify-center w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <ButtonBefore onPrevious={onPreviousTab} />
        </div>
        <div className="flex justify-center w-full md:w-1/3 px-3">
          <ButtonNext onNext={handleNext} />
        </div>
      </div>
    </form>
  );
};

export default Form3Component;
