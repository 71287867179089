import React, { useRef, useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Form1Component from "./Form1";
import Form2Component from "./Form2";
import Form3Component from "./Form3";
import Form4Component from "./Form4";
import "../styles/TabsComponent.css";
import PriceDetails from "./PriceDetails";
import IncompleteFields from "./IncompleteFields";
import axios from "axios";
import JSZip from "jszip";
import IncompleteBanner from "./IncompleteBanner";
import { useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import MyPdf from "../utils/pdf";
import { Constant } from "../utils/constants";
import mailBody from "../utils/mailBody";
import { calculatePriceDetails } from "../utils/calculations";
import { HeaderComponent } from "./headerComponent";
import { useAppNavigation } from "../utils/navigations";
import PaymentError from "./PaymentError";
import { useLocation } from "react-router-dom";

const TabsComponent = () => {
  // Formularios:

  const getLocalStorageForm = (formName) => {
    const formData = localStorage.getItem(formName);
    return formData ? JSON.parse(formData) : null;
  };

  const [reports, setReports] = useState(false);
  const [differentModel, setDifferentModel] = useState(false);

  const handleChangeReports = (value) => {
    setReports(value);
  };

  const handleChangeDifferentModel = (value) => {
    setDifferentModel(value);
  };

  // Formularios:

  const [vehicleForm, setVehicleForm] = useState(
    getLocalStorageForm("vehicleForm") || {
      brand: Constant.fields(),
      matriculationDate: Constant.fields(),
      fuel: Constant.fields(),
      vehicleModel: Constant.fields(),
      chassisNumber: Constant.fields("", undefined, false),
      saleDate: Constant.fields(),
      plate: Constant.fields(),
      price: Constant.fields(),
      buyerProvince: Constant.fields(),
      vehicleCC: Constant.fields(),
      cylinders: Constant.fields(),
      fiscalStrenght: Constant.fields(),
      kwPower: Constant.fields(),
      cvPower: Constant.fields(),
      newPrice: Constant.fields(0, true, true),
    }
  );

  const [buyerForm, setBuyerForm] = useState(
    getLocalStorageForm("buyerForm") || {
      buyerDni: Constant.fields(),
      buyerName: Constant.fields(),
      buyerGender: Constant.fields(),
      buyerLastName1: Constant.fields(),
      buyerLastName2: Constant.fields(),
      buyerBirthDate: Constant.fields(),
      buyerEmail: Constant.fields(),
      buyerPhone: Constant.fields(),
      buyerAddress: Constant.fields(),
      buyerCity: Constant.fields(),
      buyerProvince: Constant.fields(),
      buyerPostalCode: Constant.fields(),
      // Datos adicionales de direccion
      buyerAuxPostalCode: Constant.fields("", undefined, false),
      buyerAuxProvince: Constant.fields("", undefined, false),
      buyerAuxCity: Constant.fields("", undefined, false),
      buyerAuxAddress: Constant.fields("", undefined, false),
    }
  );

  const [sellerForm, setSellerForm] = useState(
    getLocalStorageForm("sellerForm") || {
      sellerDni: Constant.fields(),
      sellerName: Constant.fields(),
      sellerGender: Constant.fields(),
      sellerLastName1: Constant.fields(),
      sellerLastName2: Constant.fields(),
      sellerBirthDate: Constant.fields(),
      sellerEmail: Constant.fields(),
      sellerPhone: Constant.fields(),
      sellerAddress: Constant.fields(),
      sellerCity: Constant.fields(),
      sellerProvince: Constant.fields(),
      sellerPostalCode: Constant.fields(),
      // Datos adicionales de direccion
      sellerAuxPostalCode: Constant.fields("", undefined, false),
      sellerAuxProvince: Constant.fields("", undefined, false),
      sellerAuxCity: Constant.fields("", undefined, false),
      sellerAuxAddress: Constant.fields("", undefined, false),
    }
  );

  const [documentationForm, setDocumentationForm] = useState(
    getLocalStorageForm("documentationForm") || {
      dniFront: Constant.fields(),
      dniBack: Constant.fields(),
      sellerDniFront: Constant.fields(),
      sellerDniBack: Constant.fields(),
      techDataFront: Constant.fields(),
      techDataBack: Constant.fields(),
      circulationPermit: Constant.fields(),
      contractDocument: Constant.fields(),
      extra: [],
    }
  );

  // Datos para el desglose de costes
  const [priceDetailsData, setPriceDetailsData] = useState(
    calculatePriceDetails(vehicleForm, differentModel, reports)
  );

  const [extraDocs, setExtraDocs] = useState([]);

  const handleChangeExtraDocs = (docs) => {
    documentationForm.extra = docs;
    console.log("extra ", documentationForm.extra);
  };

  // Usuario
  const [email, setEmail] = useState();
  const [comprador, setComprador] = useState(false);

  const handleChangeUsuario = (user) => {
    console.log(user);
    if (user === "buyer") {
      setComprador(true);
      setEmail(buyerForm.buyerEmail.value);
    } else if (user === "seller") {
      setComprador(true);
      setEmail(sellerForm.sellerEmail.value);
    }
    console.log(email);
  };

  useEffect(() => {
    comprador
      ? setEmail(buyerForm.buyerEmail.value)
      : setEmail(sellerForm.sellerEmail.value);
  }, [buyerForm.buyerEmail.value, sellerForm.sellerEmail.value, comprador]);

  // Campos incompletos
  const [showIncompleteBannerVehicle, setShowIncompleteBannerVehicle] =
    useState(false);
  const [showIncompleteBannerBuyer, setShowIncompleteBannerBuyer] =
    useState(false);
  const [showIncompleteBannerSeller, setShowIncompleteBannerSeller] =
    useState(false);
  const [
    showIncompleteBannerDocumentation,
    setShowIncompleteBannerDocumentation,
  ] = useState(false);

  const data = [
    {
      label: "Vehículo",
      value: "vehicle",
      formComponent: Form1Component,
      formData: vehicleForm,
      formKey: "vehicle",
    },
    {
      label: "Comprador",
      value: "buyer",
      formComponent: Form2Component,
      formData: buyerForm,
      formKey: "buyer",
    },
    {
      label: "Vendedor",
      value: "seller",
      formComponent: Form3Component,
      formData: sellerForm,
      formKey: "seller",
    },
    {
      label: "Documentos",
      value: "documentation",
      formComponent: Form4Component,
      formData: documentationForm,
      formKey: "documentation",
    },
  ];

  useEffect(() => {
    // Formulario Vehiculo
    const isFormIncomplete = Object.values(vehicleForm).some(
      (field) =>
        (field.value === "" && field.required === true) || field.valid === false
    );
    setShowIncompleteBannerVehicle(isFormIncomplete);
    // priceDetails(calculatePriceDetails(vehicleForm, differentModel, reports));
  }, [vehicleForm]);

  useEffect(() => {
    // Formulario Comprador
    const isFormIncomplete = Object.values(buyerForm).some(
      (field) =>
        (field.value === "" && field.required === true) || field.valid === false
    );
    setShowIncompleteBannerBuyer(isFormIncomplete);
  }, [buyerForm]);

  useEffect(() => {
    // Formulario Vendedor
    const isFormIncomplete = Object.values(sellerForm).some(
      (field) =>
        (field.value === "" && field.required === true) || field.valid === false
    );
    setShowIncompleteBannerSeller(isFormIncomplete);
  }, [sellerForm]);

  useEffect(() => {
    // Formulario Documentacion
    const isFormIncomplete = Object.values(documentationForm).some(
      (field) =>
        (field.value === "" && field.required === true) || field.valid === false
    );
    setShowIncompleteBannerDocumentation(isFormIncomplete);
  }, [documentationForm]);

  const handleChange = (formName, fieldName, response) => {
    console.log("Combio en Tabs", formName, fieldName, response);

    switch (formName) {
      case "vehicle":
        setVehicleForm((prevForm) => ({ ...prevForm, [fieldName]: response }));
        break;
      case "buyer":
        setBuyerForm((prevForm) => ({ ...prevForm, [fieldName]: response }));
        break;
      case "seller":
        setSellerForm((prevForm) => ({ ...prevForm, [fieldName]: response }));
        break;
      case "documentation":
        setDocumentationForm((prevForm) => ({
          ...prevForm,
          [fieldName]: response,
        }));
        break;
      default:
        break;
    }
  };

  const defaultValue = data[0].value;
  const [activeTab, setActiveTab] = React.useState(defaultValue);
  const headerRef = useRef(null);

  const handleNextTab = () => {
    const currentIndex = data.findIndex((tab) => tab.value === activeTab);
    const nextIndex = currentIndex + 1;
    if (nextIndex < data.length) {
      setActiveTab(data[nextIndex].value);
      const tabElement = headerRef.current.querySelector(
        `[data-value="${data[nextIndex].value}"]`
      );
      if (tabElement) {
        tabElement.click();
      }
    }
    const element = document.getElementById("redirect-top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePreviousTab = () => {
    const currentIndex = data.findIndex((tab) => tab.value === activeTab);
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      setActiveTab(data[previousIndex].value);
      const tabElement = headerRef.current.querySelector(
        `[data-value="${data[previousIndex].value}"]`
      );
      if (tabElement) {
        tabElement.click();
      }
    }
    const element = document.getElementById("redirect-top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onTabsHeaderMounted = (ref) => {
    headerRef.current = ref;
  };

  const [anyIncompleteField, setAnyIncompleteField] = useState(false);

  const fileToBlob = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        resolve(blob);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  };

  // Comprueba si los campos necesarios han sido rellenados
  const checkIncompleteFields = () => {
    if (
      showIncompleteBannerVehicle ||
      showIncompleteBannerSeller ||
      showIncompleteBannerBuyer ||
      showIncompleteBannerDocumentation
    ) {
      setAnyIncompleteField(true);
      return true;
    }
  };

  const { navigateTo } = useAppNavigation();

  // Manda el correo electronico con los datos solicitados
  const sendMail = async () => {
    // Crear una instancia de JSZip
    const zip = new JSZip();

    // Generar el PDF y agregarlo al archivo ZIP
    const fileName = "contrato.pdf";
    const pdfBlob = await pdf(<MyPdf formData={pdfData()} />).toBlob();
    zip.file(fileName, pdfBlob, { binary: true });

    console.log("DocForm: ", documentationForm);

    // Agregar las imágenes al archivo ZIP como blobs
    const addFileToZip = async (
      zip,
      fileName,
      file,
      customFileName = false
    ) => {
      console.log("File ", file);
      if (file) {
        const blob = await fileToBlob(file);
        console.log("Blob: ", blob);
        const type = "." + blob.type.split("/")[1];
        const finalFileName = customFileName
          ? fileName.split(".")[0] + type
          : fileName + type;
        console.log("Nombre final ", finalFileName);
        zip.file(finalFileName, blob, { binary: true });
      }
    };

    await addFileToZip(
      zip,
      Constant.MAIL.DNI_COM_FRONT,
      documentationForm.dniFront.value
    );
    await addFileToZip(
      zip,
      Constant.MAIL.DNI_COM_REVER,
      documentationForm.dniBack.value
    );
    await addFileToZip(
      zip,
      Constant.MAIL.DNI_VEN_FRONT,
      documentationForm.sellerDniFront.value
    );
    await addFileToZip(
      zip,
      Constant.MAIL.DNI_VEN_REVER,
      documentationForm.sellerDniBack.value
    );
    await addFileToZip(
      zip,
      Constant.MAIL.DATO_TEC_FRONT,
      documentationForm.techDataFront.value
    );
    await addFileToZip(
      zip,
      Constant.MAIL.DATO_TEC_REVER,
      documentationForm.techDataBack.value
    );
    await addFileToZip(
      zip,
      Constant.MAIL.PER_CIRCULA,
      documentationForm.circulationPermit.value
    );
    await addFileToZip(
      zip,
      Constant.MAIL.DOC_CONTRATO,
      documentationForm.contractDocument.value
    );

    await Promise.all(
      documentationForm.extra.map(async (file) => {
        console.log("Documentacion extra", file);
        await addFileToZip(zip, `Extra-${file.value.name}`, file.value, true);
      })
    );

    // Generar el archivo zip
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Creamos el cuerpo del correo
    console.log("Formulario; ", vehicleForm);
    const priceDetailsInfo = calculatePriceDetails(
      vehicleForm,
      differentModel,
      reports
    );
    const htmlMailBody = mailBody(
      vehicleForm,
      buyerForm,
      sellerForm,
      priceDetailsInfo
    );

    const mails = [
      `${sellerForm.sellerEmail.value}`,
      `${buyerForm.buyerEmail.value}`,
    ];

    // Crear un objeto FormData para enviar el archivo zip al backend
    const formData = new FormData();
    formData.append("zipFile", zipBlob, Constant.MAIL.ZIP_NAME);
    formData.append("to", mails);
    formData.append("subject", "Herrera Consultores");
    formData.append("html", htmlMailBody);
    formData.append("text", "Herrera Consultores");

    console.log("detalles de precios ", priceDetailsInfo);

    if (
      priceDetailsInfo.total !== null &&
      priceDetailsInfo.total !== undefined &&
      priceDetailsInfo.total !== "--"
    ) {
      navigateTo("/payment", {
        state: {
          priceDetailsInfo,
          zipBlob,
          htmlMailBody,
          mails,
        },
      });
    } else {
      //Llama a la API en el backend para enviar el correo
      try {
        const response = await axios.post(
          `${Constant.SERVER_URL}/api/send-email`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Correo enviado:", response.data);
        navigateTo(`/resultado-pago?estado=${response.data}`);
      } catch (error) {
        console.error("Error al enviar el correo:", error);
      }
    }
  };

  const pdfData = () => {
    return {
      sellerName: sellerForm.sellerName.value,
      sellerLastName1: sellerForm.sellerLastName1.value,
      sellerLastName2: sellerForm.sellerLastName2.value,
      sellerDNI: sellerForm.sellerDni.value,
      sellerCity: sellerForm.sellerCity.value,
      sellerProvince: sellerForm.sellerProvince.value,
      sellerAddress: sellerForm.sellerAddress.value,
      buyerName: buyerForm.buyerName.value,
      buyerLastName1: buyerForm.buyerLastName1.value,
      buyerLastName2: buyerForm.buyerLastName2.value,
      buyerDNI: buyerForm.buyerDni.value,
      buyerCity: buyerForm.buyerCity.value,
      buyerProvince: buyerForm.buyerProvince.value,
      buyerAddress: buyerForm.buyerAddress.value,
      plate: vehicleForm.plate.value,
      fiscalStrenght: vehicleForm.fiscalStrenght.value,
      brand: vehicleForm.brand.value,
      vehicleModel: vehicleForm.vehicleModel.value,
      vehicleCC: vehicleForm.vehicleCC.value,
      cylinders: vehicleForm.cylinders.value,
      fuel: vehicleForm.fuel.value,
      chassisNumber: vehicleForm.chassisNumber.value,
      price: vehicleForm.price.value,
    };
  };

  const location = useLocation();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Verifica si se pasó `showBanner` a través de location.state
    if (location.state && location.state.showBanner !== undefined) {
      setShowBanner(location.state.showBanner);
    } else {
      setShowBanner(false); // Asegúrate de que el banner esté oculto si no hay estado
    }
  }, [location.state]);

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      <div className={showBanner ? "flex" : "hidden"}>
        <PaymentError setBanner={handleCloseBanner} />
      </div>

      <HeaderComponent />
      <Tabs value={activeTab} className="tabs-container">
        <TabsHeader
          className="-ml-16 md:ml-0 rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className: "shadow-none",
          }}
          ref={onTabsHeaderMounted}
        >
          {data.map(({ label, value }, index) => (
            <Tab
              key={value}
              onClick={() => setActiveTab(value)}
              className="flex bg-transparent border-b-2 border-gray-900 shadow-none rounded-none"
              value={value}
            >
              <div className="flex flex-col w-full">
                <div className={anyIncompleteField ? "h-5" : "h-5 hidden"}>
                  {value === "vehicle" && showIncompleteBannerVehicle && (
                    <IncompleteBanner />
                  )}
                  {value === "buyer" && showIncompleteBannerBuyer && (
                    <IncompleteBanner />
                  )}
                  {value === "seller" && showIncompleteBannerSeller && (
                    <IncompleteBanner />
                  )}
                  {value === "documentation" &&
                    showIncompleteBannerDocumentation && <IncompleteBanner />}
                </div>
                <div
                  className={`${
                    activeTab === value ? "selected-tab" : "default-tab"
                  } ${index === 0 ? "first-tab" : ""} tab w-[21vw]`}
                >
                  <span className="tab-number w-full">{index + 1}</span>
                  <p className="hidden md:flex">{label}</p>
                </div>
              </div>
            </Tab>
          ))}
        </TabsHeader>

        <div className="grid grid-cols-3 gap-4 md:ml-24">
          <div className="col-span-3 md:col-span-2">
            <TabsBody className="overflow-visible mt-11">
              {data.map(
                ({ value, formComponent, formData, formKey }, index) => (
                  <TabPanel key={value} value={value} className="max-h-none">
                    {React.createElement(formComponent, {
                      onNextTab: handleNextTab,
                      onPreviousTab: handlePreviousTab,
                      formData: formData,
                      onChange: (fieldName, value) =>
                        handleChange(formKey, fieldName, value),
                      downloadData: pdfData(),
                      diffModel: handleChangeDifferentModel,
                      handleChangeReports: handleChangeReports,
                      handleChangeUsuario: handleChangeUsuario,
                      handleChangeDocs: handleChangeExtraDocs,
                      checkIncompleteFields: checkIncompleteFields,
                      sendMail: sendMail,
                    })}
                  </TabPanel>
                )
              )}
            </TabsBody>
          </div>
          <div className="grid col-span-3 -mt-12 md:mt-0 md:col-span-1">
            <section>
              <PriceDetails
                vehicle={vehicleForm}
                differentModel={differentModel}
                reports={reports}
              />
            </section>
            <section className={anyIncompleteField ? "block" : "hidden"}>
              <IncompleteFields data={data} />
            </section>
          </div>
        </div>
      </Tabs>
    </>
  );
};

export default TabsComponent;
