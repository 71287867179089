export const HeaderComponent = () => {
  return (
    <div id="redirect-top" class="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular">
      <div class="et_pb_row et_pb_row_0 et_pb_gutters2">
        <div class="et_pb_column et_pb_column_1_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough">
          <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">
            <div class="et_pb_text_inner">
              <p>Herrera Consultores</p>
            </div>
          </div>
        </div>
        <div class="et_pb_column et_pb_column_3_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child">
          <div class="et_pb_module et_pb_divider et_pb_divider_0 et_pb_divider_position_center et_pb_space">
            <div class="et_pb_divider_internal"></div>
          </div>
        </div>
      </div>
      <div class="et_pb_row et_pb_row_1">
        <div class="et_pb_column et_pb_column_2  et_pb_css_mix_blend_mode_passthrough">
          <div class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
            <div class="et_pb_text_inner">
              <h1>Transferencia de vehículo</h1>
            </div>
          </div>
          <div class="et_pb_button_module_wrapper et_pb_button_0_wrapper  et_pb_module">
            <p>
              Introduce los datos del vehículo del que se va a realizar la
              transferencia.
            </p>
          </div>
        </div>
        <div class="et_pb_column et_pb_column_2 float-right et_pb_css_mix_blend_mode_passthrough et-last-child">
          <div class="et_pb_button_module_wrapper et_pb_button_0_wrapper  et_pb_module">
            <p class="text-center small-text">Contacta en el</p>
          </div>
          <div class="et_pb_button_module_wrapper et_pb_button_1_wrapper et_pb_button_alignment_center et_pb_module">
            <a
              class="et_pb_button et_pb_button_1 et_pb_bg_layout_light button-layout"
              href="tel:954815846"
            >
              954 81 58 46
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
