import * as React from "react";
import "../styles/home.css";

export default function IncompleteFields({ data }) {
  // Función para obtener los campos vacíos de un formData
  const getEmptyFields = (formData) => {
    return Object.keys(formData).filter(
      (key) =>
        formData[key].valid === false ||
        (formData[key].value === "" && formData[key].required === true)
    );
  };

  const formDataNames = {
    vehicle: {
      brand: "Marca",
      matriculationDate: "Fecha de matriculación",
      fuel: "Combustible",
      vehicleModel: "Modelo del vehículo",
      chassisNumber: "Número de bastidor",
      saleDate: "Fecha de venta",
      plate: "Matrícula",
      price: "Precio de venta",
      buyerProvince: "Provincia del comprador",
      vehicleCC: "Cilindrada",
      cylinders: "Cilindros",
      fiscalStrenght: "Fuerza física",
      kwPower: "Potencia (kw)",
      cvPower: "Potencia (CV)",
      newPrice: "Precio",
    },
    buyer: {
      buyerDni: "DNI",
      buyerName: "Nombre",
      buyerGender: "Género",
      buyerLastName1: "Primer apellido",
      buyerLastName2: "Segundo apellido",
      buyerBirthDate: "Fecha de nacimiento",
      buyerEmail: "Correo electrónico",
      buyerPhone: "Teléfono",
      buyerAddress: "Dirección",
      buyerCity: "Ciudad",
      buyerProvince: "Provincia",
      buyerPostalCode: "Código postal",
      buyerAuxPostalCode: "Código postal aux.",
      buyerAuxProvince: "Provincia aux.",
      buyerAuxCity: "Ciudad aux.",
      buyerAuxAddress: "Dirección aux."
    },
    seller: {
      sellerDni: "DNI",
      sellerName: "Nombre",
      sellerGender: "Género",
      sellerLastName1: "Primer apellido",
      sellerLastName2: "Segundo apellido",
      sellerBirthDate: "Fecha de nacimiento",
      sellerEmail: "Correo electrónico",
      sellerPhone: "Teléfono",
      sellerAddress: "Dirección",
      sellerCity: "Ciudad",
      sellerProvince: "Provincia",
      sellerPostalCode: "Código postal",
      sellerAuxPostalCode: "Código postal aux.",
      sellerAuxProvince: "Provincia aux.",
      sellerAuxCity: "Ciudad aux.",
      sellerAuxAddress: "Dirección aux."
    },
    documentation: {
      dniFront: "DNI (anverso)",
      dniBack: "DNI (reverso)",
      sellerDniFront: "DNI del vendedor (anverso)",
      sellerDniBack: "DNI del vendedor (reverso)",
      techDataFront: "Datos técnicos (anverso)",
      techDataBack: "Datos técnicos (reverso)",
      circulationPermit: "Permiso de circulación",
      contractDocument: "Documento de contrato",
    },
  };

  // Verificar si hay algún campo vacío en el formData actual
  const hasEmptyFields = (formDataItem) => {
    return getEmptyFields(formDataItem.formData).length > 0;
  };

  return data.some(hasEmptyFields) ? (
    <div className="invalid-fields p-8 mt-8 rounded-md">
      <div className="invalid-details-title mb-5 text-xl">
        <span>Debes completar los siguientes datos</span>
      </div>

      {data.map(
        (formDataItem, index) =>
          hasEmptyFields(formDataItem) && (
            <div key={`form-data-item-${index}`} className="ml-4 form-item">
              <span className="flex items-center text-red-500">
                <p className="mr-6 text-2xl font-bold">{index + 1}</p>
                {formDataItem.label}
              </span>
              {/* Obtener los campos vacíos o inválidos del formData actual */}
              {getEmptyFields(formDataItem.formData).map((fieldName, index) => (
                <div className="text-sm flex ml-8" key={`empty-field-${index}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    color="red"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    class="mr-2 w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                  {formDataNames[formDataItem.formKey][fieldName]}
                </div>
              ))}
            </div>
          )
      )}
    </div>
  ) : null;
}
