import React, { useEffect, useState } from "react";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import { Constant } from "../utils/constants";

export default function AddressFormFields({
  onChange,
  buyerPostalCode,
  buyerCity,
  buyerProvince,
  buyerAddress,
}) {

  const [datos, setDatos] = useState([]);

  useEffect(() => {
    if (buyerPostalCode.value !== "") {
      fetch(`${Constant.SERVER_URL}/api/postalCode?postalCode=${buyerPostalCode.value}`)
        .then((res) => res.json())
        .then((data) => {
          console.log("Datos del municipio", data);
          setDatos(data);

          if (data.length === 1) {
            const localidad = data[0].nombre_municipio;
            const pro = data[0].nombre_provincia;
            handleChangeCity("buyerAuxCity", localidad);
            onChange("buyerAuxProvince", pro);
          } else if (data.length === 0) {
            buyerPostalCode.valid = false;
            buyerCity.valid = undefined;
            buyerProvince.valid = undefined;
          }
        })
        .catch((error) => {
          console.error("Error al obtener los datos del municipio:", error);
        });
    } else if (buyerPostalCode.value === "") {
      onChange("buyerAuxCity", "");
      buyerCity.valid = false;
      onChange("buyerAuxProvince", "");
      buyerProvince.valid = false;
      setDatos([]);
    } else {
      setDatos([]);
      onChange("buyerAuxProvince", "");
    }
  }, [buyerPostalCode.value]);

  const handleChangeCity = (fieldName, value) => {
    onChange(fieldName, value);

    const dato = datos.find((dato) => dato.nombre_municipio === value);

    if (dato) {
      onChange("buyerAuxProvince", dato.nombre_provincia);
    }
  };

  const handleChangeNumericInput = (fieldName, value) => {
    if (/^\d*$/.test(value)) {
      onChange(fieldName, value);
    }
  };

  return (
    <>
      <div className="flex flex-wrap -mx-3 md:mb-2">
        <div className="w-full md:w-1/3 mb-2 px-3 md:mb-0">
          <InputComponent
            type="text"
            placeholder="Código Postal"
            id="aux-postalCodeInput"
            name="aux-postalCode"
            value={buyerPostalCode.value}
            onChange={(e) => {
              handleChangeNumericInput("buyerAuxPostalCode", e.target.value);
            }}
            isValid={buyerPostalCode.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <SelectComponent
            placeholder="Localidad"
            id="aux-buyerCity"
            name="aux-buyerCity"
            options={datos
              .map((datos) => datos.nombre_municipio)
              .filter((value, index, self) => self.indexOf(value) === index)}
            value={buyerCity.value}
            onChange={(e) => {
              handleChangeCity("buyerAuxCity", e.target.value);
            }}
            isValid={buyerCity.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <SelectComponent
            placeholder="Provincia"
            options={[buyerProvince.value] ? [buyerProvince.value] : []}
            id="aux-buyerProvince"
            name="aux-buyerProvince"
            value={buyerProvince.value}
            onChange={(e) => {
              onChange("buyerAuxProvince", e.target.value);
            }}
            isValid={buyerProvince.valid}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-3/3 px-3">
          <InputComponent
            type="text"
            placeholder="Dirección"
            id="aux-addressInput"
            name="aux-address"
            value={buyerAddress.value}
            onChange={(e) => {
              onChange("buyerAuxAddress", e.target.value);
            }}
            isValid={buyerAddress.valid}
          />
        </div>
      </div>
    </>
  );
}
