import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

const Checkbox = ({ checked }) => (
  <View style={styles.checkbox}>
    {checked && <View style={styles.checked} />}
  </View>
);

const MyPdf = ({ formData }) => {
  console.log("formdata: ", formData);

  const {
    sellerName,
    sellerLastName1,
    sellerLastName2,
    sellerDNI,
    sellerCity,
    sellerProvince,
    sellerAddress,
    buyerName,
    buyerLastName1,
    buyerLastName2,
    buyerDNI,
    buyerCity,
    buyerProvince,
    buyerAddress,
    plate,
    fiscalStrenght,
    brand,
    vehicleModel,
    vehicleCC,
    cylinders,
    fuel,
    chassisNumber,
    price,
  } = formData;

  const currentDate = new Date();

  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const formattedDate = `${currentDate.getDate()} de ${
    meses[currentDate.getMonth()]
  } de ${currentDate.getFullYear()}`;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Contrato de Compra - Venta de Vehículo</Text>
        <Text style={styles.text}>
          En Osuna, a {formattedDate} , reunidos POR UNA PARTE D./Dña.{" "}
          {`${formData.sellerName} ${formData.sellerLastName1} ${formData.sellerLastName2}`}
          , mayor de edad, con D.N.I. número {formData.sellerDNI + " "}
          con domicilio en {formData.sellerProvince} , provincia de{" "}
          {formData.sellerCity + " "} en la calle {formData.sellerAddress} en
          concepto de VENDEDOR y actuando
        </Text>
        <View style={styles.section}>
          <View style={styles.checkboxWithText}>
            <Checkbox checked={false} />
            <Text style={styles.checkboxText}>en nombre propio</Text>
          </View>
          <View style={styles.checkboxWithText}>
            <Checkbox checked={false} />
            <Text style={styles.checkboxText}>
              como representante de ______________________________________{" "}
            </Text>
          </View>
        </View>
        <Text style={styles.text}>
          Y EN OTRA PARTE, D./Dña.{" "}
          {`${formData.buyerName} ${formData.buyerLastName1} ${formData.buyerLastName2}, `}
          mayor de edad, con D.N.I. número {formData.buyerDNI} con domicilio en
          {" " + formData.buyerProvince} provincia de {formData.buyerCity + " "}{" "}
          en la calle {formData.buyerAddress} en concepto de COMPRADOR y
          actuando
        </Text>
        <View style={styles.section}>
          <View style={styles.checkboxWithText}>
            <Checkbox checked={false} />
            <Text style={styles.checkboxText}>en nombre propio</Text>
          </View>
          <View style={styles.checkboxWithText}>
            <Checkbox checked={false} />
            <Text style={styles.checkboxText}>
              como representante de ______________________________________
            </Text>
          </View>
        </View>
        <Text style={styles.subTitle}>Declaran</Text>
        <Text style={styles.text}>
          Haber convenido por el presente contrato de compra-venta llever a cabo
          a efecto y formalizar la compra-venta del vehículo de las
          características siguientes:
        </Text>
        <View style={styles.columns}>
          <Text style={styles.textColumn}>Matrícula: {plate}</Text>
          <Text style={styles.textColumn}>Marca: {brand}</Text>
          <Text style={styles.textColumn}>Tipo: {vehicleModel}</Text>
        </View>
        <View style={styles.columns}>
          <Text style={styles.textColumn}>Potencia: {fiscalStrenght}</Text>
          <Text style={styles.textColumn}>Motor: {vehicleCC}</Text>
          <Text style={styles.textColumn}>Bastidor: {chassisNumber}</Text>
        </View>
        <Text style={styles.text}>
          Declarando el vendedor en el presente acto, que el vehículo es de su
          completa y exclusiva propiedad y se halla totalmente libre de
          embargos, cargas y gravámenes incluidos los derivados de la
          legislación vigente, así como queda el vendedor obligado a atender las
          obligaciones contraídas hasta la firma delpresente contrato,
          respondiendo el vendedor en todo momento de las responsabilidades que
          por cualquier motivo, causa o razón apareciesen contra el vehículo
          automóvil objeto del presente contrato, obligándose a indemnizar al
          comprador por cualquier reclamación aún las injustifcadas que pudieran
          hacerse y que corresponden a actos anteriores al día de la fecha de la
          firma del contrato.
        </Text>
        <Text style={styles.text}>
          El comprador, queda obligado a efectuar la transferencia que la
          legislación vigente ordena, aceptando cuantas responsabilidades se
          originen contra el vehículo a partir del día de la fecha, quedando
          conformede las condiciones mecánicas y generales de conservación con
          que recibe el vehículo y los accesorios correspondientes.
        </Text>
        <Text style={styles.text}>
          El precio de esta compra-venta es de EUROS {price} -
          ___________________________________________ cuyo importe total recibe
          el vendedor en el acto de la firma del presente contrato otorgando por
          ello la mas eficaz carta de pago al comprador del vehículo automóvil
          en cuestión.
        </Text>
        <Text style={styles.text}>
          Y para que así conste, firman el presente contrato por duplicado y a
          un sólo efecto, en el lugar y fecha indicados.
        </Text>
        <View style={styles.columnsPeople}>
          <Text style={styles.personSign}>EL VENDEDOR con Nº D.N.I.</Text>
          <Text style={styles.personSign}>EL COMPRADOR con Nº D.N.I.</Text>
        </View>
      </Page>
    </Document>
  );
};

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    border: "1 solid black",
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  subTitle: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: "Times-Roman",
    margin: 10,
  },
  text: {
    marginVertical: 8,
    marginHorizontal: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  checkboxWithText: {
    marginHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    width: 10,
    height: 10,
    border: "1 solid black",
    marginRight: 5,
    position: "relative",
  },
  checkboxText: {
    fontSize: 12,
    fontFamily: "Times-Roman",
  },
  checked: {
    backgroundColor: "black",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0.5,
  },
  columns: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    paddingLeft: 50,
    fontFamily: "Times-Roman",
    width: "100%",
  },
  textColumn: {
    padding: 4,
    width: "30%",
    textAlign: "left",
  },
  columnsPeople: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    paddingTop: 15,
    justifyContent: "space-around",
    textAlign: "center",
    fontFamily: "Times-Roman",
    width: "100%",
  },
  personSign: {
    width: "19%",
  },
});

export default MyPdf;
