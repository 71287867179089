import dayjs from "dayjs";
import { porcentajeDepreciacion } from "../utils/calculations";
import { Constant } from "../utils/constants";

export default function PriceDetails({ vehicle, differentModel, reports }) {
  const { fiscalStrenght, matriculationDate, newPrice, buyerProvince } =
    vehicle;

  const andalucianProvinces = [
    "Almería",
    "Cádiz",
    "Córdoba",
    "Granada",
    "Huelva",
    "Jaén",
    "Málaga",
    "Sevilla",
  ];

  const isValidProvince =
    buyerProvince?.value && andalucianProvinces.includes(buyerProvince.value);
  const isValidMatriculationDate = matriculationDate?.value;
  const isDifferentModel = !differentModel;

  let porcentajeITP = 4;
  let valoracionTablasHacienda = newPrice?.value || "--";
  let porcentajeDeprecia = "--";
  let valorFiscal = "--";
  let ITP = "--";
  let TOTAL = "--";

  if (isValidProvince && isValidMatriculationDate && isDifferentModel) {

    // Validar la fecha antes de pasarla a dayjs
    const parsedDate = dayjs(matriculationDate.value, "MM/DD/YYYY");
    if (parsedDate.isValid()) {
      porcentajeDeprecia =
        porcentajeDepreciacion(parsedDate.format("DD/MM/YYYY")) || "--";

      if (porcentajeDeprecia !== "--") {
        valorFiscal = (porcentajeDeprecia * valoracionTablasHacienda) / 100;
        ITP = (porcentajeITP * valorFiscal) / 100;

        TOTAL =
          ITP +
          Constant.DGT_TRANSFERENCIA +
          Constant.TRAMITES_MENSAJERIA +
          Constant.POLIZA_COLEGIO +
          Constant.IVA +
          (reports ? Constant.DGT_INFORME : 0);
      }
    } else {
      console.error("Fecha inválida:", matriculationDate.value);
    }
  }

  return (
    <div className="border-4 border-solid border-herrera-light-orange text-left p-8 mt-12 rounded-md">
      <div className="font-bold text-2xl text-herrera-orange mb-5">
        <span>Desglose de costes</span>
      </div>

      <section
        className={`flex w-full justify-between font-normal ${
          ITP !== "--" ? "" : "pb-4"
        }`}
      >
        <div className="w-2/4">ITP ({porcentajeITP} %)</div>
        <div>{ITP !== "--" ? ITP.toFixed(2) : "--"} €</div>
      </section>
      <section
        className={`flex-col w-full py-4 font-normal text-sm ${
          ITP !== "--" ? "flex" : "hidden"
        }`}
      >
        <div className="flex w-full justify-between px-4 py-1">
          <div className="w-3/4">Valoración tablas Hacienda</div>
          <div>
            {valoracionTablasHacienda !== "--"
              ? valoracionTablasHacienda + " €"
              : "--"}
          </div>
        </div>
        <div className="flex w-full justify-between px-4 py-1">
          <div className="w-3/4">Porcentaje Depreciación</div>
          <div>
            {porcentajeDeprecia !== "--" ? porcentajeDeprecia + " %" : "--"}
          </div>
        </div>
        <div className="flex w-full justify-between px-4 py-1">
          <div className="w-3/4">Valor fiscal</div>
          <div>{valorFiscal !== "--" ? valorFiscal + " €" : "--"}</div>
        </div>
      </section>
      <section className="flex w-full pb-4 justify-between font-normal">
        <div className="w-3/4">Tasas DGT - Transferencia</div>
        <div>{ITP !== "--" ? Constant.DGT_TRANSFERENCIA : "--"} €</div>
      </section>
      {reports && (
        <section className="flex w-full pb-4 justify-between font-normal">
          <div className="w-3/4">Tasas DGT - Informes</div>
          <div>{ITP !== "--" ? Constant.DGT_INFORME : "--"} €</div>
        </section>
      )}
      <section className="flex w-full pb-4 justify-between font-normal">
        <div className="w-3/4">Poliza colegio</div>
        <div>{ITP !== "--" ? Constant.POLIZA_COLEGIO : "--"} €</div>
      </section>
      <section className="flex w-full pb-4 justify-between font-normal">
        <div className="w-3/4">Trámites y mensajería</div>
        <div>{ITP !== "--" ? Constant.TRAMITES_MENSAJERIA : "--"} €</div>
      </section>
      <section className="flex w-full pb-4 justify-between font-normal">
        <div className="w-3/4">IVA</div>
        <div>{ITP !== "--" ? Constant.IVA : "--"} €</div>
      </section>
      <section className="flex w-full justify-between font-extrabold">
        <div className="w-2/4">Total (IVA incl.)</div>
        <div>{TOTAL !== "--" ? TOTAL.toFixed(2) : "--"} €</div>
      </section>
      <section className="font-normal text-sm mt-2">
        <p>*Precio provisional</p>
      </section>
    </div>
  );
}
