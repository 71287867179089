import * as React from "react";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importar el locale español
import "../styles/home.css";
import { PickersLayoutRoot } from "@mui/x-date-pickers/PickersLayout";

// Establecer el locale en dayjs
dayjs.locale("es");

function DateLabelIcon({ labelName, isOpen }) {
  return (
    <>
      {labelName}
      <svg
        className="pl-4 h-8 w-8 text-blue-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </>
  );
}

function ButtonField({
  setOpen,
  label,
  id,
  disabled,
  isValid,
  InputProps: { ref } = {},
  inputProps: { "aria-label": ariaLabel } = {},
}) {
  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      style={{
        textTransform: "none",
        fontFamily: "Poppins",
        fontWeight: 300,
        lineHeight: 1.25,
        fontSize: 16,
        border: "1px solid #ededed",
        justifyContent: "space-between",
      }}
      className={`text-left appearance-none min-h-12 block w-full border rounded mb-4 focus:outline-none focus:border-black focus:bg-white placeholder-blue-700 ${
        isValid === undefined
          ? " "
          : isValid
          ? " valid-border"
          : " invalid-border"
      }`}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label}
    </Button>
  );
}

function ButtonDatePicker({ isValid, minDate, maxDate, ...props }) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{
        ...props.slots,
        field: ButtonField,
      }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen, isValid },
        calendarHeader: {
          sx: {
            borderBottom: "1px #a6a8a9 solid",
            display: "flex",
            justifyContent: "center",
            color: "#2196f3",
          },
        },
        layout: {
          ownerState: props,
          sx: { color: "#2196f3" },
        },
        popper: {
          sx: {
            "& .MuiPickersCalendarHeader-labelContainer, & .MuiPickersCalendarHeader-switchViewButton":
              {
                color: "#2196f3",
              },
            "& .MuiDayPicker-weekDayLabel, & .MuiDayPicker-day, & .MuiDayPicker-month, & .MuiPickersDay-root":
              {
                color: "#2196f3",
              },
            "& .MuiPickersYear-yearButton, & .MuiPickersMonth-monthButton": {
              color: "#2196f3",
            },
          },
        },
      }}
      minDate={minDate}
      maxDate={maxDate}
      monthsPerRow={3}
      yearsPerRow={3}
      {...props}
      open={open}
      openTo="year"
      views={["year", "month", "day"]}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function PickerWithButtonField({
  labelName,
  value,
  onChange,
  isValid,
  minDate,
  maxDate,
  ...rest
}) {
  const handleChangeDate = (newValue) => {
    onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <ButtonDatePicker
        label={
          <DateLabelIcon
            labelName={value == null ? labelName : value.format("DD/MM/YYYY")}
          />
        }
        value={value}
        onChange={handleChangeDate}
        isValid={isValid}
        minDate={minDate}
        maxDate={maxDate}
        {...rest}
      />
    </LocalizationProvider>
  );
}
