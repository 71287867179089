export default function Cross({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.5 2L2 30.5" stroke="#F56D0D" stroke-width="3" />
      <path d="M2 2.5L30.5 31" stroke="#F56D0D" stroke-width="3" />
    </svg>
  );
}
