import React, { useEffect, useState } from "react";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import { Constant } from "../utils/constants";

export default function AddressFormFieldsSeller({
  onChange,
  sellerPostalCode = { value: "", valid: undefined },
  sellerCity = { value: "", valid: undefined },
  sellerProvince = { value: "", valid: undefined },
  sellerAddress = { value: "", valid: undefined },
}) {
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    if (sellerPostalCode.value !== "") {
      fetch(`${Constant.SERVER_URL}/api/postalCode?postalCode=${sellerPostalCode.value}`)
        .then((res) => res.json())
        .then((data) => {
          console.log("Datos del municipio", data);
          setDatos(data);

          if (data.length === 1) {
            const localidad = data[0].nombre_municipio;
            const pro = data[0].nombre_provincia;
            handleChangeCity("sellerAuxCity", localidad);
            onChange("sellerAuxProvince", pro);
          } else if (data.length === 0) {
            sellerPostalCode.valid = false;
            sellerCity.valid = undefined;
            sellerProvince.valid = undefined;
          }
        })
        .catch((error) => {
          console.error("Error al obtener los datos del municipio:", error);
        });
    } else if (sellerPostalCode.value === "") {
      onChange("sellerAuxCity", "");
      sellerCity.valid = false;
      onChange("sellerAuxProvince", "");
      sellerProvince.valid = false;
      setDatos([]);
    } else {
      setDatos([]);
      onChange("sellerAuxProvince", "");
    }
  }, [sellerPostalCode.value]);

  const handleChangeCity = (fieldName, value) => {
    onChange(fieldName, value);

    const dato = datos.find((dato) => dato.nombre_municipio === value);

    if (dato) {
      onChange("sellerAuxProvince", dato.nombre_provincia);
    }
  };

  const handleChangeNumericInput = (fieldName, value) => {
    if (/^\d*$/.test(value)) {
      onChange(fieldName, value);
    }
  };

  return (
    <>
      <div className="flex flex-wrap -mx-3 md:mb-2">
        <div className="w-full md:w-1/3 mb-2 px-3 md:mb-0">
          <InputComponent
            type="text"
            placeholder="Código Postal"
            id="aux-sellerPostalCodeInput"
            name="aux-sellerPostalCode"
            value={sellerPostalCode.value}
            onChange={(e) => {
              handleChangeNumericInput("sellerAuxPostalCode", e.target.value);
            }}
            isValid={sellerPostalCode.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <SelectComponent
            placeholder="Localidad"
            id="aux-sellerCity"
            name="aux-sellerCity"
            options={datos
              .map((datos) => datos.nombre_municipio)
              .filter((value, index, self) => self.indexOf(value) === index)}
            value={sellerCity.value}
            onChange={(e) => {
              handleChangeCity("sellerAuxCity", e.target.value);
            }}
            isValid={sellerCity.valid}
          />
        </div>
        <div className="w-full md:w-1/3 mb-2 md:mb-0 px-3">
          <SelectComponent
            placeholder="Provincia"
            options={[sellerProvince.value] ? [sellerProvince.value] : []}
            id="aux-sellerProvince"
            name="aux-sellerProvince"
            value={sellerProvince.value}
            onChange={(e) => {
              onChange("sellerAuxProvince", e.target.value);
            }}
            isValid={sellerProvince.valid}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-3/3 px-3">
          <InputComponent
            type="text"
            placeholder="Dirección"
            id="aux-sellerAddressInput"
            name="aux-SellerAddress"
            value={sellerAddress.value}
            onChange={(e) => {
              onChange("sellerAuxAddress", e.target.value);
            }}
            isValid={sellerAddress.valid}
          />
        </div>
      </div>
    </>
  );
}
