import { Tooltip, Typography } from "@material-tailwind/react";

export function TooltipWithHelperIcon({ text, image }) {
  return (
    <Tooltip
      className="border border-blue-gray-50 body-tooltip px-4 py-3 shadow-xl shadow-black/10"
      placement="top-start"
      content={
        <section className="flex flex-col max-w-80">
          <div className={`${image ? "mb-4" : ""} w-80`}>
            <Typography
              variant="small"
              color="white"
              className="font-normal opacity-80"
            >
              {text}
            </Typography>
          </div>
          {image && (
            <div className="flex">
              <img
                className="w-full bg-blue-gray-700"
                src={image}
                alt={""}
              ></img>
            </div>
          )}
        </section>
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
        className="h-5 w-5 cursor-pointer icon-tooltip"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
    </Tooltip>
  );
}
