import React from "react";
import Cross from "../utils/svg/cross";

export default function PaymentError({ setBanner }) {
  return (
    <div className="fixed inset-0 flex z-50 items-center justify-center bg-black bg-opacity-60">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-xl mx-auto text-left relative">
        <button
          className="absolute top-0 right-0 m-2 mr-12 mt-10 text-gray-600 hover:text-gray-900"
          onClick={() => {
            setBanner();
          }}
        >
          <Cross size={"18"} />
        </button>
        <h2 className="text-3xl font-bold mb-4 mt-16">
          ¡Vaya! Parece que ha habido un error en el pago
        </h2>
        <p className="text-sm text-gray-800 font-normal mb-4">
          Ha habido un problema al procesar el pago. Por favor, intente de nuevo
          más tarde.
        </p>
      </div>
    </div>
  );
}
