// Vehiculo
// Numero serie
export const isValidChassisNumber = (chassisNumber) => {
  if (!chassisNumber || chassisNumber === "") {
    return false;
  } else {
    const chassisNumberRegex = /^[^IOQioq]{17}$/;
    console.log("Comprobando chassis number");
    return chassisNumberRegex.test(chassisNumber);
  }
};

// Matricula
export const isPlateValid = (plate) => {
  if (!plate) {
    return false;
  } else {
    const plateRegex = /^(?:\d{4}[A-Z]{3}|[A-Z]{1,2}\d{4}[A-Z]{2})$/;
    return plateRegex.test(plate);
  }
};

// Fuerza fisica
export const isValidFiscalStrenght = (fiscalStrenght) => {
  if (!fiscalStrenght) {
    return false;
  } else {
    const fiscalStrenghtRegex = /^(0|[1-9]\d*)(,\d{1,2})?$/;
    return fiscalStrenghtRegex.test(fiscalStrenght);
  }
};

// Centimetros cubicos
export const isValidVehicleCC = (vehicleCC) => {
  if (!vehicleCC) {
    return false;
  } else {
    const vehicleCCRegex = /^[1-9]\d*$/;
    return vehicleCCRegex.test(vehicleCC);
  }
};

// Nombre
export const validateName = (name) => {
  const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s']+$/u;
  return nameRegex.test(name);
};

// Apellidos
export const validateLastName = (lastName) => {
  const apellidoExpReg = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s']+$/;
  return apellidoExpReg.test(lastName);
};

// Email
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Codigo postal
export const validatePostalCode = (postalCode) => {
  const postalCodeRegex = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/;
  return postalCodeRegex.test(postalCode);
};

// DNI
export const validateDNI = (dni) => {
  const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
  return dniRegex.test(dni);
};

// Telefono
export const validatePhone = (phone) => {
  const phoneRegex = /^[0-9]{9}$/;
  return phoneRegex.test(phone);
};

// +18 Fecha de nacimiento (mayor de edad)
export const validateBirthDate = (birthDate) => {
  const [dia, mes, anio] = birthDate.split("/").map(Number);

  const fechaNacimientoDate = new Date(anio, mes - 1, dia);
  const fechaActual = new Date();
  let edad = fechaActual.getFullYear() - fechaNacimientoDate.getFullYear();

  const mesActual = fechaActual.getMonth();
  const diaActual = fechaActual.getDate();
  const mesNacimiento = fechaNacimientoDate.getMonth();
  const diaNacimiento = fechaNacimientoDate.getDate();

  if (
    mesActual < mesNacimiento ||
    (mesActual === mesNacimiento && diaActual < diaNacimiento)
  ) {
    edad--;
  }

  // Devolver true si la edad es mayor o igual a 18, de lo contrario false
  return edad >= 18;
};

// Validar select. Que haya un campo seleccionado al menos.
export const validateSelect = (selectValue) => {
  if (selectValue !== "" && selectValue) {
    return true;
  } else {
    return false;
  }
};

// Valida si el archivo subido es inferior a 50mb y es una imagen
export const validateFileInput = (file) => {
  const validImageTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/bmp",
    "image/svg+xml",
    "image/tiff",
    "image/x-icon",
  ];
  if (!validImageTypes.includes(file.type)) {
    console.log("Formato invalido");
    return 1; // Es invalido debido al formato
  }

  const maxSize = 50 * 1024 * 1024; // 500 MB
  if (file.size > maxSize) {
    console.log("Tamaño invalido");
    return 2; // Es invalido debido al tamaño
  }

  return true; // Es valido
};

// Valida si el archivo es un pdf
export const validatePdf = (file) => {
  const validPdfType = "application/pdf";
  if (file.type !== validPdfType) {
    console.log("Formato invalido");
    return false; // Es invalido
  }

  return true; // Es valido
};

export const withoutValidation = (value) => {
  return value !== "" ? true : false;
};

// Precio por antiguedad (precio de venta)
export const precioAntiguedad = (price, vehiclePrice, date) => {
  const antiguedad = new Date().getFullYear() - date;

  if (!vehiclePrice) {
    return true;
  }

  const tablaDepreciacion = [
    { antiguedadMaxima: 1, porcentaje: 100 },
    { antiguedadMaxima: 2, porcentaje: 84 },
    { antiguedadMaxima: 3, porcentaje: 67 },
    { antiguedadMaxima: 4, porcentaje: 56 },
    { antiguedadMaxima: 5, porcentaje: 47 },
    { antiguedadMaxima: 6, porcentaje: 39 },
    { antiguedadMaxima: 7, porcentaje: 34 },
    { antiguedadMaxima: 8, porcentaje: 28 },
    { antiguedadMaxima: 9, porcentaje: 24 },
    { antiguedadMaxima: 10, porcentaje: 19 },
    { antiguedadMaxima: 11, porcentaje: 17 },
    { antiguedadMaxima: 12, porcentaje: 13 },
    { antiguedadMaxima: Infinity, porcentaje: 10 },
  ];

  for (const { antiguedadMaxima, porcentaje } of tablaDepreciacion) {
    if (antiguedad <= antiguedadMaxima) {
      console.log("Porcentaje: ", porcentaje);

      const result = (porcentaje * vehiclePrice) / 100;
      console.log("Cantidad: ", result);

      if (price >= (porcentaje * vehiclePrice) / 100) {
        return true;
      }
      return false;
    }
  }

  return false;
};

// Función para mapear los valores de combustible devueltos por la API a los valores esperados
export const mapFuelType = (fuelType) => {
  switch (fuelType) {
    case "G":
      return "Gasolina";
    case "D":
      return "Diésel";
    case "M":
      return "Etanol + Gasolina o Bio";
    case "S":
      return "Gasolina GLP";
    case "Elc":
      return "Eléctrico";
    case "GyE":
      return "Híbrido Gasolina Eléctrico";
    case "DyE":
      return "Híbrido Diésel Eléctrico";
    default:
      return fuelType;
  }
};
