import { useNavigate } from "react-router-dom";

export const useAppNavigation = () => {
  const navigate = useNavigate();

  const goTop = () => {
    const element = document.getElementById("redirect-top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateTo = (url, params) => {
    navigate(url, params);
    goTop();
  };

  return {
    navigateTo,
  };
};
