import React, { useState, useEffect, useRef } from "react";

export default function SearchableSelect({
  placeholder,
  onInputChange,
  initialOptions = [],
  onSelectedOption,
  id,
  name,
  isValid,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(initialOptions);
  const selectRef = useRef(null);

  useEffect(() => {
    // Actualiza filteredOptions cuando initialOptions cambie
    setFilteredOptions(initialOptions);
  }, [initialOptions]);

  const handleInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    if (value === "") {
      // Si el campo de entrada está vacío, mostramos todas las opciones
      setFilteredOptions(initialOptions);
      setShowOptions(true);
      onInputChange("");
    } else {
      // Verifica si onInputChange es una función que retorna una promesa
      const result = onInputChange(value);
      if (result instanceof Promise) {
        // Si es una promesa, maneja la resolución
        result.then((opt) => {
          setFilteredOptions(opt || []); // Asegúrate de que el resultado sea un array
          setShowOptions(true);
        });
      } else {
        // Si no es una promesa, asume que es un array filtrado
        setFilteredOptions(result || []); // Asegúrate de que el resultado sea un array
        setShowOptions(true);
      }
    }
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option); // Establece el campo de entrada con la opción seleccionada
    onSelectedOption(option);
    setShowOptions(false);
  };

  // Manejar clic fuera del componente
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  // Condicionar las clases de los bordes según el valor de isValid
  const inputClassNames = `appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white placeholder-blue-700 ${
    isValid === true
      ? "valid-border"
      : isValid === false
      ? "invalid-border"
      : "border-gray-300"
  }`;

  return (
    <div className="relative w-full" ref={selectRef}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => setShowOptions(true)}
        placeholder={placeholder}
        className={inputClassNames} // Usar las clases condicionadas
      />
      {showOptions && filteredOptions.length > 0 && (
        <div className="absolute z-20 w-full mt-2">
          <ul className="list-none border border-black rounded shadow-lg max-h-44 overflow-y-scroll overflow-x-clip bg-white">
            {filteredOptions.map((opt, index) => (
              <li
                className="flex text-left pl-4 text-blue-700 cursor-pointer hover:bg-blue-700 hover:text-white"
                key={index}
                onClick={() => handleOptionClick(opt)}
              >
                {opt}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
