// src/routes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import PaymentMethod from "./components/payment/layout";
import TabsComponent from "./components/Tabs";
import PaymentResult from "./components/payment/paymentResult";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<TabsComponent />} />
    <Route path="/payment" element={<PaymentMethod />} />
    <Route path="/payment-ok" element={<PaymentMethod />} />
    <Route path="/payment-ko" element={<PaymentMethod />} />
    <Route path="/resultado-pago" element={<PaymentResult />} />
  </Routes>
);

export default AppRoutes;
