// InputComponent.jsx
import React from "react";

const InputComponent = ({ type, placeholder, isValid, icon, ...rest }) => {
  const inputClasses = `appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white placeholder-blue-700 
    ${
      isValid === undefined ? "" : isValid ? "valid-border" : "invalid-border"
    }`;

  return (
    <div className="relative w-full">
      <input
        type={type}
        placeholder={placeholder}
        className={inputClasses}
        {...rest}
      />
      {icon && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {icon}
        </div>
      )}
    </div>
  );
};

export default InputComponent;
