import dayjs from "dayjs";
import { Constant } from "./constants";

const tablaDepreciacion = [
  { antiguedadMaxima: 1, porcentaje: 100 },
  { antiguedadMaxima: 2, porcentaje: 84 },
  { antiguedadMaxima: 3, porcentaje: 67 },
  { antiguedadMaxima: 4, porcentaje: 56 },
  { antiguedadMaxima: 5, porcentaje: 47 },
  { antiguedadMaxima: 6, porcentaje: 39 },
  { antiguedadMaxima: 7, porcentaje: 34 },
  { antiguedadMaxima: 8, porcentaje: 28 },
  { antiguedadMaxima: 9, porcentaje: 24 },
  { antiguedadMaxima: 10, porcentaje: 19 },
  { antiguedadMaxima: 11, porcentaje: 17 },
  { antiguedadMaxima: 12, porcentaje: 13 },
  { antiguedadMaxima: Infinity, porcentaje: 10 },
];
export const DGT_TRANSFERENCIA = 55.7;
export const DGT_INFORME = 8.67;
export const SERVICIO_HERRERA = 35;

// ITP

// Valoracion tablas hacienda

export const precioAntiguedad = (vehiclePrice, date) => {
  const parsedDate = dayjs(date, "MM/DD/YYYY");
  if (!parsedDate.isValid()) {
    console.error("Fecha inválida:", date);
    return null;
  }

  const antiguedad = dayjs().diff(parsedDate, "year");

  for (const { antiguedadMaxima, porcentaje } of tablaDepreciacion) {
    if (antiguedad <= antiguedadMaxima) {
      const result = (porcentaje * vehiclePrice) / 100;

      return result;
    }
  }

  return false;
};

// Porcentaje depreciación
export const porcentajeDepreciacion = (date) => {
  const parsedDate = dayjs(date, "MM/DD/YYYY");
  if (!parsedDate.isValid()) {
    console.error("Fecha inválida:", date);
    return null;
  }

  const antiguedad = dayjs().diff(parsedDate, "year");
  console.log("Antiguedad del vehículo:", antiguedad);

  for (const { antiguedadMaxima, porcentaje } of tablaDepreciacion) {
    if (antiguedad <= antiguedadMaxima) {
      console.log("Porcentaje de depreciación:", porcentaje);
      return porcentaje;
    }
  }

  return false; // En caso de que algo falle, aunque no debería ocurrir
};

export const calculatePriceDetails = (vehicle, differentModel, reports) => {
  if (!differentModel) {
    const { fiscalStrenght, matriculationDate, newPrice, buyerProvince } =
      vehicle;

    let porcentajeITP = "--";
    let valoracionTablasHacienda = "--";
    let porcentajeDeprecia = "--";
    let valorFiscal = "--";
    let ITP = "--";
    let TOTAL = "--";
    let informeDGT = "--";

    const andalucianProvinces = [
      "Almería",
      "Cádiz",
      "Córdoba",
      "Granada",
      "Huelva",
      "Jaén",
      "Málaga",
      "Sevilla",
    ];

    if (
      buyerProvince.value &&
      matriculationDate.value &&
      andalucianProvinces.includes(buyerProvince.value) &&
      !differentModel
    ) {
      const parsedDate = dayjs(matriculationDate.value, "MM/DD/YYYY");

      porcentajeITP = 4;
      valoracionTablasHacienda = newPrice.value;
      porcentajeDeprecia =
        porcentajeDepreciacion(parsedDate.format("DD/MM/YYYY")) || "--";
      console.log("Porcentaje  depre. ", porcentajeDeprecia);
      valorFiscal = (porcentajeDeprecia * valoracionTablasHacienda) / 100;
      ITP = (porcentajeITP * valorFiscal) / 100;

      TOTAL =
        ITP +
        Constant.DGT_TRANSFERENCIA +
        Constant.TRAMITES_MENSAJERIA +
        Constant.IVA +
        Constant.POLIZA_COLEGIO;
      if (reports) {
        TOTAL += Constant.DGT_INFORME;
        informeDGT = Constant.DGT_INFORME;
      }
    }

    return {
      porcentajeITP: porcentajeITP || null,
      valorITP: ITP || null,
      valoracionTablasHacienda: valoracionTablasHacienda || null,
      porcentajeDeprecia: porcentajeDeprecia || null,
      valorFiscal: valorFiscal || null,
      transferenciaDGT: Constant.DGT_TRANSFERENCIA || null,
      polizaColegio: Constant.POLIZA_COLEGIO || null,
      iva: Constant.IVA || null,
      informeDGT: informeDGT === "--" ? null : informeDGT,
      tramitesMensajeria: Constant.TRAMITES_MENSAJERIA || null,
      total: TOTAL !== "--" ? TOTAL.toFixed(2) : null,
    };
  }

  return null;
};
