import React, { useState } from "react";
import PaymentMethodHeader from "./header";
import PaymentPrices from "./prices";
import PaymentButton from "./button";
import ButtonBefore from "../ButtonBefore";
import { useLocation } from "react-router-dom";
import { useAppNavigation } from "../../utils/navigations";
import { Constant } from "../../utils/constants";
import axios from "axios";

export default function PaymentMethod() {
  const [prices, setPrices] = useState({});
  const [zip, setZip] = useState();
  const [htmlBody, setHtmlBody] = useState();
  const [mail, setMail] = useState();
  const { navigateTo } = useAppNavigation();
  const location = useLocation();
  const { priceDetailsInfo, zipBlob, htmlMailBody, mails } =
    location.state || {};

  React.useEffect(() => {
    if (priceDetailsInfo) {
      setPrices(priceDetailsInfo);
    }
    if (zipBlob) {
      setZip(zipBlob);
    }
    if (mails) {
      setMail(mails);
    }
    if (htmlMailBody) {
      setHtmlBody(htmlMailBody);
    }
  }, [priceDetailsInfo, zipBlob, htmlMailBody, mails]);

  const pay = async () => {
    const orderId = Constant.GENERATE_ORDER_ID();
    const amount = priceDetailsInfo.total;

    const formData = new FormData();
    formData.append("orderId", orderId);
    formData.append("amount", amount);
    formData.append("zip", zip);
    formData.append("mail", mail);
    formData.append("htmlBody", htmlBody);

    try {
      const response = await axios.post(
        `${Constant.SERVER_URL}/pago`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const formHtml = response.data;

      const div = document.createElement("div");
      div.innerHTML = formHtml;
      document.body.appendChild(div);
      div.querySelector("form").submit();
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };

  return (
    <div className="flex flex-col">
      <PaymentMethodHeader />
      <section className="flex w-full justify-center">
        <PaymentPrices priceDetails={prices} />
      </section>
      <div className="flex flex-col md:flex-row md:w-3/5 self-center justify-between">
        <ButtonBefore onPrevious={() => navigateTo("/")} />
        <PaymentButton onNext={pay} />
      </div>
    </div>
  );
}
