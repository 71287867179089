import React, { useState } from "react";

const ToggleUser = ({ label, isChecked, onToggle }) => {
  return (
    <div className="flex justify-between mb-2 font-normal toggle-button py-3 pl-5 pr-2 border rounded">
      <div className="self-center">{label}</div>
      <div className="justify-self-end flex px-1 py-1">
        <label className="flex cursor-pointer select-none items-center">
          <div className='relative'>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={onToggle}
              className="sr-only"
            />
            <div
              className={`block h-8 w-14 rounded-full ${
                isChecked ? "color-blue-custom" : "color-gray-custom"
              }`}
            ></div>
            <div
              className={`dot absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition ${
                isChecked ? "transform translate-x-full" : ""
              }`}
            ></div>
          </div>
        </label>
      </div>
    </div>
  );
};

const ToggleContainer = ({usuario}) => {
  const [buyerChecked, setBuyerChecked] = useState(false);
  const [sellerChecked, setSellerChecked] = useState(true);

  const handleBuyerToggle = () => {
    setBuyerChecked(!buyerChecked);
    setSellerChecked(buyerChecked);
    buyerChecked ? usuario("buyer") : usuario("seller")
  };

  const handleSellerToggle = () => {
    setSellerChecked(!sellerChecked);
    setBuyerChecked(sellerChecked);
    sellerChecked ? usuario("seller") : usuario("buyer")
  };

  return (
    <div>
      <ToggleUser
        label="Soy el comprador"
        isChecked={buyerChecked}
        onToggle={handleBuyerToggle}
      />
      <ToggleUser
        label="Soy el vendedor"
        isChecked={sellerChecked}
        onToggle={handleSellerToggle}
      />
    </div>
  );
};

export default ToggleContainer;
