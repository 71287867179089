import React, { useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import "../styles/ButtonComponent.css";

export default function ButtonUpload({ onChange, isFileValid }) {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const buttonClasses = `form-button-download normal-case flex justify-between items-center font-medium w-full py-4
                ${
                  isFileValid === undefined
                    ? ""
                    : isFileValid
                    ? "valid-border"
                    : "invalid-border"
                }
                ${isDragging ? "dragging-bg" : ""}`;

  const handleButtonClick = () => {
    // Simular un clic en el input de archivos al hacer clic en el botón
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    onChange(event);
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      fileInputRef.current.files = dataTransfer.files;
      onChange({ target: { files: dataTransfer.files } });
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button
        className={buttonClasses}
        onClick={handleButtonClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        Subir contrato firmado
        <svg
          className="Icon_awesome-long-arrow-alt-ri"
          viewBox="0 10.258 31.5 15.484"
        >
          <g transform="rotate(-90) scale(0.7) translate(-40, 0)">
            <path
              id="Icon_awesome-long-arrow-alt-ri"
              className="color-arrow-before"
              d="M 22.07397651672363 15.1875 L 0.84375 15.1875 C 0.3777890503406525 15.1875 0 15.56528949737549 0 16.03125 L 0 19.96875 C 0 20.43471145629883 0.3777890503406525 20.8125 0.84375 20.8125 L 22.07397651672363 20.8125 L 22.07397651672363 24.05102348327637 C 22.07397651672363 25.55444526672363 23.89162445068359 26.30735206604004 24.95475006103516 25.24429702758789 L 31.00577354431152 19.19327354431152 C 31.6648120880127 18.53423500061035 31.6648120880127 17.46576499938965 31.00577354431152 16.80679702758789 L 24.95475006103516 10.75577354431152 C 23.89169502258301 9.692718505859375 22.07397651672363 10.44562530517578 22.07397651672363 11.94904708862305 L 22.07397651672363 15.1875 Z"
            ></path>
          </g>
        </svg>
      </Button>
    </>
  );
}
