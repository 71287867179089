export default function FileErrorExtension() {
  return (
    <div
      className="flex place-content-center text-xs text-red-800 rounded-lg dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0,0,256,256"
      >
        <g
          fill="#e40101"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
        >
          <g transform="scale(5.12,5.12)">
            <path d="M7,2v46h36v-33.40625l-0.28125,-0.3125l-12,-12l-0.3125,-0.28125zM9,4h20v12h12v30h-32zM31,5.4375l8.5625,8.5625h-8.5625zM15,22v2h20v-2zM15,28v2h16v-2zM15,34v2h20v-2z"></path>
          </g>
        </g>
      </svg>

      <span className="ml-1 self-center">Formato incorrecto</span>
    </div>
  );
}
