import React from "react";

export default function TechnicalData ({ vehicleData, visibility }) {
  const {
    brand,
    vehicleModel,
    vehicleCC,
    cylinders,
    fuel,
    kwPower,
    fiscalStrenght,
    cvPower,
  } = vehicleData;
  const nothing = "--------";

  return (
    <div
      className={`md:w-full flex flex-col border border-solid border-gray-200 rounded text-blue-700 ${
        visibility ? "" : "hidden"
      }`}
    >
      <div className="w-full border-b border-solid border-gray-200">
        <h3 className="text-left mt-4 mb-2 ml-6 text-blue-700">
          Datos técnicos del vehículo
        </h3>
      </div>
      <section className="flex flex-col my-4">
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Marca:
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {brand.value !== "" ? brand.value : nothing}
          </p>
        </div>
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Modelo:
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {vehicleModel.value !== "" ? vehicleModel.value : nothing}
          </p>
        </div>
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Cilindrada:
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {vehicleCC.value !== "" ? vehicleCC.value : nothing}
          </p>
        </div>
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Cilindros:
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {cylinders.value !== "" ? cylinders.value : nothing}
          </p>
        </div>
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Combustible:
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {fuel.value !== "" ? fuel.value : nothing}
          </p>
        </div>
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Potencia (Kw):
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {kwPower.value !== "" ? kwPower.value : nothing}
          </p>
        </div>
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Potencia fiscal:
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {fiscalStrenght.value !== "" ? fiscalStrenght.value : nothing}
          </p>
        </div>
        <div className="flex w-full h-fit">
          <p className="flex self-start w-1/2 md:w-1/3 min-w-max pl-6 pr-5">
            Potencia (CV):
          </p>
          <p className="flex self-start w-1/2 md:w-2/3">
            {cvPower.value !== "" ? cvPower.value : nothing}
          </p>
        </div>
      </section>
    </div>
  );
}
