import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export const Constant = {
  // --> FECHAS
  FECHA_MATRICULACION_MIN: dayjs("1970-01-01"), // Fecha de matriculacion minima
  FECHA_MATRICULACION_MAX: dayjs(), // Fecha actual

  FECHA_VENTA_MIN: dayjs().subtract(30, "day"), // Fecha actual - 30 días
  FECHA_VENTA_MAX: dayjs(), // Fecha actual

  // --> PRECIOS (€)
  DGT_TRANSFERENCIA: 55.7, // Precio de la DGT para la transferencia
  DGT_INFORME: 8.67, // Precio por el informe DGT (este campo puede o no estar en la calculadora)
  TRAMITES_MENSAJERIA: 45,
  POLIZA_COLEGIO: 6,
  IVA: 9.45,

  // --> FICHEROS
  FICHEROS_EXTRA_MAX: 5, // Ficheros extra máximos permitidos

  // --> Nombres para los ficheros que se envian por correo
  MAIL: {
    ZIP_NAME: "documentos.zip",

    DNI_COM_FRONT: "DNI-comprador-frontal",
    DNI_COM_REVER: "DNI-comprador-reverso",
    DNI_VEN_FRONT: "DNI-vendedor-frontal",
    DNI_VEN_REVER: "DNI-vendedor-reverso",
    DATO_TEC_FRONT: "Datos-técnicos-frontal",
    DATO_TEC_REVER: "Datos-técnicos-reverso",
    PER_CIRCULA: "Permiso-circulación",
    DOC_CONTRATO: "Documento-contrato",
  },

  // --> TIPOS DE COMBUSTIBLE
  FUELS: [
    "Gasolina",
    "Diésel",
    "Etanol + Gasolina o Bio",
    "Gasolina GLP",
    "Eléctrico",
    "Híbrido Gasolina Eléctrico",
    "Híbrido Diésel Eléctrico",
  ],

  // --> ATRIBUTOS DE LAS VARIABLES
  fields: (value = "", valid = undefined, required = true) => {
    return { value: `${value}`, valid: valid, required: required };
  },

  // --> IR HACIA ARRIBA DE LA WEB

  goTop: () => {
    const element = document.getElementById("redirect-top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  },

  SERVER_URL: "https://api.transferencias.herreraconsultores.net",
  // SERVER_URL: "http://localhost:3001",

  /**
   * Genera y devuelve un ID compuesto por 12 caracteres numericos
   * (Redsys necesita que sean 12 caracteres)
   * @returns String
   */
  GENERATE_ORDER_ID: () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Ultimos 2 digitos del año
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Mes (0-11) -> (1-12)
    const day = now.getDate().toString().padStart(2, "0"); // Dia del mes
    const hours = now.getHours().toString().padStart(2, "0"); // Horas
    const minutes = now.getMinutes().toString().padStart(2, "0"); // Minutos
    const seconds = now.getSeconds().toString().padStart(2, "0"); // Segundos
    const milliseconds = now.getMilliseconds().toString().padStart(3, "0"); // Milisegundos

    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;

    // Ajustar para obtener exactamente 12 caracteres
    return orderId.slice(0, 12);
  },

  /**
   * Navega a la ruta insertada (url) y se situa en el principio de esta
   *
   * Si queremos el efecto 'goTop()' debemos aplicar en cada pantalla un
   * elemento con 'id="redirect-top"'
   *
   * @param {*} url URL a la que queremos navegar
   * @param {*} params Opciones adicionales
   */
  NAVIGATE: (url, params) => {
    const navigate = useNavigate();
    const element = document.getElementById("redirect-top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    navigate(url, params);
  },
};
