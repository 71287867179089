import React from "react";

export default function PaymentPrices({ priceDetails }) {
  return (
    <section className="flex flex-col md:flex-row w-full md:w-3/5 gap-10 text-sm">
      <div className="border-4 border-solid border-herrera-light-orange mx-10 md:mx-0 text-left md:w-1/2 p-8 rounded-md h-full font-normal mb-4">
        <div className="font-bold text-2xl text-herrera-orange mb-5">
          <span>Cálculo ITP</span>
        </div>
        <div className="flex w-full justify-between pb-4">
          <div>Valoración tablas Hacienda</div>
          <div>{priceDetails.valoracionTablasHacienda + " €"}</div>
        </div>
        <div className="flex w-full justify-between pb-4">
          <div>Porcentaje Depreciación</div>
          <div>{priceDetails.porcentajeDeprecia + " €"}</div>
        </div>
        <div className="flex w-full justify-between pb-4">
          <div>Valor fiscal</div>
          <div>{priceDetails.valorFiscal + " €"}</div>
        </div>
        <div className="flex w-full justify-between font-extrabold">
          <div>ITP (4%)</div>
          <div>{priceDetails.valorITP + " €"}</div>
        </div>
      </div>
      <div className="border-4 border-solid border-herrera-light-orange mx-10 md:mx-0 text-left md:w-1/2 p-8 h-full rounded-md">
        <div className="font-bold text-2xl text-herrera-orange mb-5">
          <span>Desglose de costes</span>
        </div>

        <section className="flex w-full justify-between font-normal pb-4">
          <div>ITP ({priceDetails.porcentajeITP}%)</div>
          <div>{priceDetails.valorITP + " €"}</div>
        </section>

        <section className="flex w-full pb-4 justify-between font-normal">
          <div>Tasas DGT - Transferencia</div>
          <div>{priceDetails.transferenciaDGT + " €"}</div>
        </section>
        {priceDetails.informeDGT !== null && (
          <section className="flex w-full pb-4 justify-between font-normal">
            <div>Tasas DGT - Informes</div>
            <div>{priceDetails.informeDGT + " €"}</div>
          </section>
        )}
        <section className="flex w-full pb-4 justify-between font-normal">
          <div>Poliza colegio</div>
          <div>{priceDetails.polizaColegio + " €"}</div>
        </section>
        <section className="flex w-full pb-4 justify-between font-normal">
          <div>Trámites mensajería</div>
          <div>{priceDetails.tramitesMensajeria + " €"}</div>
        </section>
        <section className="flex w-full pb-4 justify-between font-normal">
          <div>IVA</div>
          <div>{priceDetails.iva + " €"}</div>
        </section>
        <section className="flex w-full justify-between font-extrabold">
          <div>Total (IVA incl.)</div>
          <div>{priceDetails.total + " €"}</div>
        </section>
      </div>
    </section>
  );
}
