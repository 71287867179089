import React from "react";

export default function SelectComponent({
  placeholder,
  isValid,
  value,
  onChange,
  options,
  readOnly,
  ...rest
}) {
  // Verifica si options es nulo o undefined, y asigna un array vacío en ese caso
  const validOptions = options || [];

  // Filtra las opciones únicas
  const uniqueOptions = validOptions.filter(
    (value, index, self) => self.indexOf(value) === index
  );

  // Verifica si solo hay una opción única y establece esa opción como la seleccionada por defecto
  const defaultValue = uniqueOptions.length === 1 ? uniqueOptions[0] : "";

  return (
    <div className="relative">
      <select
        className={`block appearance-none hover:cursor-pointer w-full bg-white border-gray-200 px-4 py-2 pr-8 rounded 
                        ${
                          isValid === undefined
                            ? ""
                            : isValid
                            ? "valid-border"
                            : "invalid-border"
                        }`}
        value={value || defaultValue} // Usa el valor de defaultValue si value es null o undefined
        onChange={onChange}
        readOnly={readOnly}
        {...rest}
      >
        <option value="" hidden>
          {placeholder}
        </option>

        {uniqueOptions.length === 1 ? (
          <option key={1} value={defaultValue}>
            {defaultValue}
          </option>
        ) : (
          uniqueOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))
        )}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2 text-gray-700">
        <svg
          className="pl-4 h-8 w-8 text-blue-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
  );
}
