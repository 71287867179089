import React, { useEffect, useState } from "react";
import ButtonNext from "./ButtonNext";
import RadioVehicle from "./RadioVehicle";
import ToggleContainer from "./ToggleContainer";
import SelectComponent from "./SelectComponent";
import InputComponent from "./InputComponent";
import TechnicalData from "./TechnicalData";
import dayjs from "dayjs";
import "../styles/Form1.css";
import "react-datepicker/dist/react-datepicker.css";
import { TooltipWithHelperIcon } from "./TooltipWithHelperIcon";
import PickerWithButtonField from "./ButtonFieldProps";
import Provincias from "../utils/provinces.json";
import {
  isPlateValid,
  isValidChassisNumber,
  isValidFiscalStrenght,
  isValidVehicleCC,
  validateSelect,
  withoutValidation,
} from "../utils/validator";
import { precioAntiguedad } from "../utils/validator";
import { Constant } from "../utils/constants";
import WheelSteel from "../utils/svg/wheelSteel";
import SearchableSelect from "./SearchableSelect";

const Form1Component = ({
  onNextTab,
  formData,
  onChange,
  handleChangeReports,
  handleChangeUsuario,
  diffModel,
}) => {
  const newFieldState = (value, isValid, required = true) => {
    return { value: `${value}`, valid: isValid, required: required };
  };

  // Todos los valores del formulario
  const [vehicleForm, setVehicleForm] = useState(formData);
  const [matriculationRange, setMatriculationRange] = useState({
    min: Constant.FECHA_MATRICULACION_MIN,
    max: Constant.FECHA_MATRICULACION_MAX,
  });
  const [matriculationDate, setMatriculationDate] = useState(
    vehicleForm.matriculationDate.value || null
  );
  const [saleDate, setSaleDate] = useState(vehicleForm.saleDate.value || null);
  const [fuel, setFuel] = useState(Constant.FUELS);

  // Vehiculo completo
  const [vehicle, setVehicle] = useState({});
  const [technicalData, setTechnicalData] = useState(false);

  // Renderizacion de componentes
  const [differentModel, setDifferentModel] = useState(diffModel);

  // Selects
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [selectModel, setSelectModel] = useState(
    newFieldState(
      vehicleForm.vehicleModel.value,
      vehicleForm.vehicleModel.valid
    ) || Constant.fields("", undefined)
  );
  const [selectBrand, setSelectBrand] = useState(
    newFieldState(vehicleForm.brand.value, vehicleForm.brand.valid) ||
      newFieldState("", undefined)
  );
  const [newBrand, setNewBrand] = useState(newFieldState("", undefined));
  const [newModel, setNewModel] = useState(newFieldState("", undefined));
  const [modelPlaceHolder, setModelPlaceHolder] = useState(() => {
    return differentModel
      ? "Mi vehículo no aparece en el listado"
      : "Modelo del vehículo";
  });
  const [brandPlaceHolder, setBrandPlaceHolder] = useState(() => {
    return differentModel
      ? "Mi vehículo no aparece en el listado"
      : "Selecciona la marca";
  });

  const saveFormToLocalStorage = (formName, formData) => {
    localStorage.setItem(formName, JSON.stringify(formData));
  };

  const handleChangeField = (fieldName, value) => {
    let isValid = undefined;

    if (validators[fieldName]) {
      if (value && value !== "") {
        const validator = validators[fieldName];
        isValid = validator(value);
      }
    }

    const newFieldStateValue = newFieldState(value, isValid);

    onChange(fieldName, newFieldStateValue); // Guardar en formulario global

    setVehicleForm((prevValue) => {
      const updatedForm = {
        ...prevValue,
        [fieldName]: newFieldStateValue,
      };

      console.log("Form actualizado: ", updatedForm);
      saveFormToLocalStorage("vehicleForm", updatedForm);

      return updatedForm;
    });
  };

  const handleChangeMatriculationDate = (newValue) => {
    setMatriculationDate(dayjs(newValue));
    handleChangeField(
      "matriculationDate",
      dayjs(newValue).format("DD/MM/YYYY")
    );
  };

  const handleChangeSaleDate = (value) => {
    setSaleDate(dayjs(value));
    handleChangeField("saleDate", dayjs(value).format("DD/MM/YYYY"));
  };

  // <---------- Validadores ---------->

  const handleChangeNumericInput = (fieldName, value) => {
    if (/^(?:[1-9]\d*)?$/.test(value)) {
      handleChangeField(fieldName, value);
    } else {
      return false;
    }
  };

  const handleChangeFloatInput = (fieldName, value) => {
    const regex = /^$|^(\d+)?(?:,\d*)?$/;
    if (regex.test(value)) {
      handleChangeField(fieldName, value);
    } else {
      return false;
    }
  };

  const isPriceValid = (price) => {
    if (!matriculationDate) {
      return false;
    }
    return precioAntiguedad(price, vehicle.VALOR_EUROS, matriculationDate.$y);
  };

  const validators = {
    brand: validateSelect,
    matriculationDate: withoutValidation,
    fuel: validateSelect,
    vehicleModel: withoutValidation,
    chassisNumber: isValidChassisNumber,
    saleDate: withoutValidation,
    plate: isPlateValid,
    price: isPriceValid,
    buyerProvince: validateSelect,
    vehicleCC: isValidVehicleCC,
    cylinders: withoutValidation,
    kwPower: withoutValidation,
    fiscalStrenght: isValidFiscalStrenght,
    cvPower: withoutValidation,
    newPrice: withoutValidation,
  };

  // <---------- PETICIONES ---------->

  useEffect(() => {
    let result = [];
    Provincias.provincias.forEach((pro) => {
      result.push(pro.nombre);
    });
    setMatchProvinces(result);
    setProvinces(result);
  }, []);

  useEffect(() => {
    // Realizar la petición a tu backend
    fetch(`${Constant.SERVER_URL}/api/brands`)
      .then((res) => res.json())
      .then((data) => {
        console.log("Todas las marcas: ", data);
        setBrands(data);
      });
  }, []);

  // Obtenemos los modelos a partir de la marca seleccionada
  useEffect(() => {
    if (selectBrand.value) {
      fetch(
        `${Constant.SERVER_URL}/api/models?brand=${selectBrand.value}&cc=${vehicleForm.vehicleCC.value}&fs=${vehicleForm.fiscalStrenght.value}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("Modelos, ", data);
          setModels(data);

          if (Array.isArray(data) && data.length === 0) {
            selectModel.valid = undefined;
          }
        });
    }
  }, [
    selectBrand.value,
    vehicleForm.vehicleCC.value,
    vehicleForm.fiscalStrenght.value,
  ]);

  useEffect(() => {
    if (selectModel.value) {
      fetch(`${Constant.SERVER_URL}/api/getVehicle?model=${selectModel.value}`)
        .then((res) => res.json())
        .then((data) => {
          console.log("Vehiculo encontrado: ", data);
          if (data[0]) {
            // Verificar si data[0] está definido
            setVehicle(data[0]);
            selectModel.valid = true;
            handleChangeField(
              "vehicleCC",
              data[0].CILINDRADA !== "null" &&
                data[0].CILINDRADA !== null &&
                data[0].CILINDRADA !== undefined &&
                data[0].CILINDRADA !== 0
                ? data[0].CILINDRADA
                : ""
            );
            handleChangeField(
              "cylinders",
              data[0].NUM_CILINDROS !== "null" &&
                data[0].NUM_CILINDROS !== null &&
                data[0].NUM_CILINDROS !== undefined &&
                data[0].NUM_CILINDROS !== 0
                ? data[0].NUM_CILINDROS
                : ""
            );
            handleChangeField(
              "fiscalStrenght",
              data[0].CVF !== "null" &&
                data[0].CVF !== null &&
                data[0].CVF !== undefined
                ? data[0].CVF.toString().replace(".", ",")
                : ""
            );
            handleChangeField(
              "kwPower",
              data[0].POTENCIA_KW !== null && data[0].POTENCIA_KW !== undefined
                ? data[0].POTENCIA_KW
                : null
            );
            handleChangeField(
              "cvPower",
              data[0].CV !== null && data[0].CV !== undefined
                ? data[0].CV
                : null
            );
            handleChangeField(
              "newPrice",
              data[0].VALOR_EUROS !== null && data[0].VALOR_EUROS !== undefined
                ? data[0].VALOR_EUROS
                : null
            );
            setTechnicalData(true);
          } else {
            // Si data[0] no está definido, establecer valores predeterminados o vacíos
            setVehicle({});
            handleChangeField("vehicleCC", null);
            handleChangeField("cylinders", null);
            handleChangeField("fiscalStrenght", null);
            handleChangeField("kwPower", null);
            handleChangeField("cvPower", null);
            handleChangeField("newPrice", null);
            setTechnicalData(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching vehicle:", error);
        });
    }
  }, [selectModel.value]);

  useEffect(() => {
    if (newModel.value !== "" && differentModel) {
      handleChangeField("vehicleModel", newModel.value);
    } else if (selectModel.value !== "" && !differentModel) {
      handleChangeField("vehicleModel", selectModel.value);
    }
  }, [newModel.value, selectModel.value]);

  useEffect(() => {
    if (newBrand.value !== "" && differentModel) {
      handleChangeField("brand", newBrand.value);
    } else if (selectBrand.value !== "" && !differentModel) {
      handleChangeField("brand", selectBrand.value);
    }
  }, [newBrand.value, selectBrand.value]);

  useEffect(() => {
    setSelectModel(newFieldState("", undefined));
    handleChangeField("vehicleModel", "");
    handleChangeField("vehicleCC", "");
    handleChangeField("fiscalStrenght", "");
  }, [selectBrand.value]);

  const getSearchBrands = (value) => {
    if (value === "") {
      vehicleForm.brand.valid = undefined;
    }
    setSelectModel(newFieldState("", undefined, true));
    handleChangeField("brand", "");
    return fetch(`${Constant.SERVER_URL}/api/get-search-brand?content=${value}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return [];
      });
  };

  const getSearchModels = (value) => {
    if (value === "") {
      vehicleForm.vehicleModel.valid = undefined;
    }
    handleChangeField("vehicleModel", "");
    return fetch(
      `${Constant.SERVER_URL}/api/get-search-model?brand=${selectBrand.value}&modelContent=${value}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("Datos de modelos: ", data);
        return data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return [];
      });
  };

  const [matchProvinces, setMatchProvinces] = useState([]);

  const normalizeString = (str) => {
    return str
      .normalize("NFD") // Normaliza el texto para dividir los caracteres acentuados en su forma base y los acentos
      .replace(/[\u0300-\u036f]/g, ""); // Elimina los acentos diacríticos
  };

  const changeMatchProvinces = (value) => {
    console.log("Valor fechaaaa: ", value);
    if (value === "") {
      handleChangeField("buyerProvince", "");
      setMatchProvinces(provinces); // Si el valor está vacío, muestra todas las provincias
    } else {
      // Normaliza el valor de búsqueda
      const normalizedValue = normalizeString(value.toLowerCase());

      // Filtrar provincias según si empiezan o contienen el valor normalizado
      const filteredProvinces = provinces.filter((province) =>
        normalizeString(province.toLowerCase()).includes(normalizedValue)
      );

      // Actualizar el estado con las provincias que coinciden
      setMatchProvinces(filteredProvinces);
    }
  };

  const handleNext = () => {
    onNextTab();
  };

  return (
    <form className="w-full">
      <div className="flex md:hidden -mt-7 w-full align-middle justify-center text-3xl text-orange-800 mb-10">
        <div className="mr-2">
          <WheelSteel />
        </div>
        <div className="flex self-center">
          <p>Vehículo</p>
        </div>
      </div>
      <div className="flex mx-1 w-full mb-12">
        <RadioVehicle />
      </div>
      <div className="flex flex-wrap mt-6 -mx-3 mb-2">
        <div className="w-full mb-2 md:w-1/3 px-3">
          <SearchableSelect
            placeholder={"Seleccione la Marca"}
            initialOptions={brands}
            onInputChange={getSearchBrands}
            onSelectedOption={(selectedOption) => {
              console.log("Marca seleccionada: ", selectedOption);
              setSelectBrand(newFieldState(selectedOption, true, true));
              setSelectModel(newFieldState("", undefined, true));
              handleChangeField("brand", selectedOption);
              console.log("Datos: ", vehicleForm.brand.valid);
            }}
            isValid={vehicleForm.brand.valid}
          />
        </div>
        <div className="w-full mb-7 md:mb-0 md:w-1/3 px-3">
          <SelectComponent
            placeholder="Combustible"
            options={fuel}
            id="fuelSelect"
            name="fuel"
            value={vehicleForm.fuel.value}
            onChange={(e) => handleChangeField("fuel", e.target.value)}
            isValid={vehicleForm.fuel.valid}
          />
        </div>
        <div className="w-full mb-5 md:mb-0 md:w-1/3 px-3 relative">
          <TooltipWithHelperIcon
            text={
              "La cilindrada aparece en P1 de la ficha técnica del vehículo"
            }
            image={"images/ficha_tecnica_cc.png"}
          />
          <InputComponent
            type="text"
            placeholder="Cilindrada del vehículo"
            id="vehicleCC"
            name="vehicleCC"
            value={
              vehicleForm.vehicleCC.value !== "null" &&
              vehicleForm.vehicleCC.value !== undefined &&
              vehicleForm.vehicleCC.value !== "0"
                ? vehicleForm.vehicleCC.value
                : ""
            }
            onChange={(e) =>
              handleChangeNumericInput("vehicleCC", e.target.value)
            }
            isValid={vehicleForm.vehicleCC.valid}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 md:mt-6 mb-2">
        <div className="w-full mb-2 md:mb-0 md:w-1/3 px-3 relative">
          <TooltipWithHelperIcon
            text={
              "La potencia fiscal aparece en P2.1 de la ficha técnica del vehículo"
            }
            image={"images/ficha_tecnica_cv.png"}
          />
          <InputComponent
            type="text"
            placeholder="Potencia fiscal del vehículo"
            id="fiscalStrenght"
            name="fiscalStrenght"
            value={
              vehicleForm.fiscalStrenght.value !== "null" &&
              vehicleForm.fiscalStrenght.value !== undefined &&
              vehicleForm.fiscalStrenght.value !== "0"
                ? vehicleForm.fiscalStrenght.value
                : ""
            }
            onChange={(e) =>
              handleChangeFloatInput("fiscalStrenght", e.target.value)
            }
            isValid={vehicleForm.fiscalStrenght.valid}
          />
        </div>
        <div className="mb-2 flex-col w-full md:w-1/3 px-3">
          <SearchableSelect
            placeholder={"Seleccione el modelo"}
            initialOptions={models}
            onInputChange={getSearchModels}
            onSelectedOption={(selectedOption) => {
              console.log("Modelo seleccionada: ", selectedOption);
              setSelectModel(newFieldState(selectedOption, true, true));
              handleChangeField("vehicleModel", selectedOption);
              console.log("Datos: ", vehicleForm.brand.valid);
            }}
            isValid={vehicleForm.vehicleModel.valid}
          />
        </div>
        <div className=" w-full md:w-1/3 px-3">
          <PickerWithButtonField
            labelName="Fecha Matriculación"
            value={
              matriculationDate !== null
                ? dayjs(matriculationDate, "DD/MM/YYYY")
                : null
            }
            onChange={handleChangeMatriculationDate}
            isValid={vehicleForm.matriculationDate.valid}
            minDate={
              matriculationRange.min
                ? matriculationRange.min
                : Constant.FECHA_MATRICULACION_MIN
            }
            maxDate={matriculationRange.max}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row -mx-3">
        <div className="flex flex-row mb-2 md:mb-0 w-full md:w-1/3 pl-3">
          <div className="pr-2 pl-1">
            <InputComponent
              type="checkbox"
              id="differentModel"
              name="differentModel"
              onChange={(e) => {
                setDifferentModel(e.target.checked);
                if (e.target.checked) {
                  setModelPlaceHolder("Mi vehículo no aparece en el listado");
                  setBrandPlaceHolder("Mi vehículo no aparece en el listado");
                  handleChangeField("brand", "");
                  handleChangeField("cvPower", "");
                  handleChangeField("cylinders", "");
                  handleChangeField("kwPower", "");
                  diffModel(true);
                  setSelectModel(newFieldState("", undefined));
                  setSelectBrand(newFieldState("", undefined));
                  setNewModel(newFieldState("", undefined));
                  setNewBrand(newFieldState("", undefined));
                  setTechnicalData(false);
                } else {
                  setModelPlaceHolder("Modelo del vehículo");
                  setBrandPlaceHolder("Selecciona la marca");
                  diffModel(false);
                  setNewBrand(newFieldState("", undefined));
                  setNewModel(newFieldState("", undefined));
                }
              }}
            />
          </div>
          <div>
            <p className="text-xs text-blue-700">
              La marca de mi vehículo no está en el listado
            </p>
          </div>
        </div>
        <div className="w-full mb-2 md:mb-0 md:w-1/3 px-3">
          <InputComponent
            type="text"
            placeholder="Matrícula"
            id="plateInput"
            name="plate"
            value={vehicleForm.plate.value}
            onChange={(e) => handleChangeField("plate", e.target.value)}
            isValid={vehicleForm.plate.valid}
          />
        </div>
        <div className="w-full md:w-1/3 px-3">
          <InputComponent
            type="text"
            placeholder="Número de bastidor"
            id="chassisNumberInput"
            name="chassisNumber"
            value={vehicleForm.chassisNumber.value}
            onChange={(e) => handleChangeField("chassisNumber", e.target.value)}
            isValid={vehicleForm.chassisNumber.valid}
          />
        </div>
      </div>
      <div
        className={`-mx-3 transition-all pt-2 md:pt-3 duration-500 ease-in-out ${
          differentModel
            ? "flex flex-wrap opacity-100 animate-fade-in-and-down pb-2"
            : "display-none pb-0 opacity-0 animate-fade-out-and-up"
        }`}
      >
        <div className="w-full mb-2 md:mb-0 md:w-1/3 px-3">
          <InputComponent
            type="text"
            placeholder="Escriba la marca del vehículo"
            id="newBrand"
            name="newBrand"
            value={newBrand.value}
            onChange={(e) => {
              differentModel
                ? setNewBrand(newFieldState(e.target.value, true))
                : setNewBrand(newFieldState("", undefined));
            }}
            isValid={newBrand.valid}
          />
        </div>
        <div className="w-full md:w-1/3 px-3 md:mb-0">
          <InputComponent
            type="text"
            placeholder="Escriba el modelo del vehículo"
            id="newVehicleModel"
            name="newVehicleModel"
            value={newModel.value}
            onChange={(e) => {
              differentModel
                ? setNewModel(newFieldState(e.target.value, true))
                : setNewModel(newFieldState("", undefined));
            }}
            isValid={newModel.valid}
          />
        </div>
      </div>
      <div
        className={`flex flex-wrap -mx-3 mt-4 mb-2 ${
          differentModel ? "pt-0" : "pt-3"
        } flex`}
      >
        <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0 relative">
          <TooltipWithHelperIcon text="No puede ser una fecha de más de 30 días previos al actual. DGT y Hacienda exigen que el contrato no tenga más de 30 días de antigüedad." />
          <PickerWithButtonField
            labelName="Fecha de la venta"
            value={saleDate !== null ? dayjs(saleDate, "DD/MM/YYYY") : null}
            onChange={handleChangeSaleDate}
            isValid={vehicleForm.saleDate.valid}
            minDate={Constant.FECHA_VENTA_MIN}
            maxDate={Constant.FECHA_VENTA_MAX}
          />
        </div>
        <div className="w-full mb-7 md:mb-0 md:w-1/3 px-3 relative">
          <InputComponent
            type="text"
            placeholder="Precio de venta"
            id="priceInput"
            name="price"
            value={vehicleForm.price.value}
            onChange={(e) => handleChangeNumericInput("price", e.target.value)}
            isValid={vehicleForm.price.valid}
          />
        </div>
        <div className="w-full mb-2 md:w-1/3 px-3 relative">
          <TooltipWithHelperIcon
            text={
              "Provincia en la que está empadronado el comprador del vehículo. De éste parámetro también depende el ITP."
            }
          />
          <SearchableSelect
            placeholder={"Provincia del comprador"}
            initialOptions={matchProvinces}
            onInputChange={changeMatchProvinces}
            onSelectedOption={(selectedOption) => {
              handleChangeField("buyerProvince", selectedOption);
            }}
            isValid={vehicleForm.buyerProvince.valid}
          />
        </div>
      </div>
      <div className="flex -mx-3 mb-2 justify-between">
        <div className="w-full md:w-1/3 px-3 md:mb-0">
          <ToggleContainer usuario={handleChangeUsuario} />
        </div>
        <div className="hidden md:flex px-3 self-end">
          <ButtonNext onNext={handleNext} />
        </div>
      </div>
      <div className="flex flex-row w-full pl-1">
        <div className="pr-2">
          <InputComponent
            type="checkbox"
            id="differentModel"
            name="differentModel"
            onChange={(e) => {
              handleChangeReports(e.target.checked);
            }}
          />
        </div>
        <div>
          <p className="text-xs text-blue-700">Necesito informes de la DGT</p>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex md:hidden justify-center">
          <ButtonNext onNext={handleNext} />
        </div>
        <div className="mt-4">
          <TechnicalData vehicleData={vehicleForm} visibility={technicalData} />
        </div>
      </div>
    </form>
  );
};

export default Form1Component;
