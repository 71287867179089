import React, { useState } from "react";
import ButtonBefore from "./ButtonBefore";
import TextDocumentNotice from "./TextDocumentNotice";
import { Checkbox, Typography } from "@material-tailwind/react";
import ButtonDownload from "./ButtonDownload";
import FileInputComponent from "./FileInputComponent";
import ButtonUpload from "./ButtonUpload";
import ButtonFinish from "./ButtonFinish";
import { validateFileInput, validatePdf } from "../utils/validator";
import MultifileInputComponent from "./MultifileInputComponent";
import { AiOutlineClose } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";
import { Constant } from "../utils/constants";
import MaxFiles from "./MaxFiles";
import Docs from "../utils/svg/docs";

const Form4Component = ({
  onPreviousTab,
  downloadData,
  formData,
  onChange,
  handleChangeDocs,
  checkIncompleteFields,
  sendMail,
}) => {
  const newFieldState = (value, isValid, required) => {
    return { value: value, valid: isValid, required: required };
  };

  const [documentForm, setDocumentForm] = useState(formData);
  const [terms, setTerms] = useState(false);

  const handleChange = (
    fieldName,
    event,
    isFileRemoval = false,
    required = true
  ) => {
    console.log("Cambiando de valor: ", fieldName + " a " + event);
    console.log(event);

    const file = event.target.files[0];
    let isValid = undefined;

    if (
      fieldName === "contractDocument" &&
      !isFileRemoval &&
      file &&
      file !== ""
    ) {
      isValid = validatePdf(file);
    } else if (!isFileRemoval && file && file !== "") {
      isValid = validateFileInput(file);
      console.log("Es válido: ", isValid);
    }

    onChange(fieldName, newFieldState(file, isValid, required)); // Guardar en formulario global

    setDocumentForm((prevalue) => {
      return {
        ...prevalue,
        [fieldName]: newFieldState(file, isValid),
      };
    });
  };

  const [extraFiles, setExtraFiles] = useState([]);

  const handleMultifileInput = (fieldName, event, required = false) => {
    const file = event.target.files[0];
    let isValid = undefined;

    if (file && file !== "") {
      isValid = validateFileInput(file);
      console.log(`El fichero ${file.name} es`, isValid);
    }

    const newFile = newFieldState(file, isValid, required);

    if (extraFiles.length < Constant.FICHEROS_EXTRA_MAX) {
      setExtraFiles((prevFiles) => [...prevFiles, newFile]);
      // onChange(fieldName, newFieldState(file, isValid, required));

      console.log(`Los archivos anteriores`, extraFiles);
      console.log(`Fichero a añadir ${file.name}: `, file);

      setDocumentForm((prevValue) => {
        return {
          ...prevValue,
          [fieldName]: [...prevValue[fieldName], newFile],
        };
      });
    } else {
      return false;
    }
  };

  const handleRemoveFile = (index) => {
    setExtraFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setDocumentForm((prevValue) => {
      return {
        ...prevValue,
        extra: prevValue.extra.filter((_, i) => i !== index),
      };
    });
  };

  const handleChangeFinish = () => {
    const incompleteFields = checkIncompleteFields();

    if (incompleteFields) {
      return false;
    }

    if (terms) {
      handleChangeDocs(extraFiles);
      sendMail();
    }
  };

  return (
    <div className="w-full">
      <div className="flex md:hidden -mt-7 w-full align-middle justify-center text-3xl text-orange-800 mb-10">
        <div className="mr-2">
          <Docs />
        </div>
        <div className="flex self-center">
          <p>Documentos</p>
        </div>
      </div>
      <div className="w-full mb-10">
        <Typography className="text-left mb-2 form-contract-title">
          Contrato
          <span className="color-blue-custom-text"> compra y venta</span>
        </Typography>
        <TextDocumentNotice
          text="Puedes descargar el contrato de compra y venta generado con los datos que nos has proporcionado.
                     Nos pondremos en contacto contigo para recibir el contrato firmado, además comprador y vendedor "
          blueText="recibiréis una copia al mail."
        />
      </div>
      <div className="w-full mb-16">
        <ButtonDownload data={downloadData} />
      </div>
      <div className="flex align-middle justify-start">
        <div className="pt-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
            color="orange"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>
        </div>
        <strong className="ml-2 text-orange-700">
          Los documentos deben ser subidos escaneados y no como fotografía.
        </strong>
      </div>
      <form className="w-full">
        <div className="w-full">
          <div className="w-full mb-4">
            <Typography className="text-left mb-2 form-contract-title">
              Documentación del
              <span className="color-blue-custom-text"> comprador</span>
            </Typography>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <FileInputComponent
                name="dniFront"
                label="DNI del comprador (frontal)"
                onChange={(e) => handleChange("dniFront", e)}
                value={documentForm.dniFront.value}
                isValid={documentForm.dniFront.valid}
              />
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <FileInputComponent
                name="dniBack"
                label="DNI del comprador (trasero)"
                onChange={(e) => handleChange("dniBack", e)}
                value={documentForm.dniBack.value}
                isValid={documentForm.dniBack.valid}
              />
            </div>
            <div className="w-full md:w-1/3 px-3"></div>
          </div>
        </div>
        <div className="w-full">
          <div className="w-full mb-4">
            <Typography className="text-left mb-2 form-contract-title">
              Documentación del
              <span className="color-blue-custom-text"> vendedor</span>
            </Typography>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <FileInputComponent
                name="sellerDniFront"
                label="DNI del vendedor (frontal)"
                onChange={(e) => handleChange("sellerDniFront", e)}
                value={documentForm.sellerDniFront.value}
                isValid={documentForm.sellerDniFront.valid}
              />
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <FileInputComponent
                name="sellerDniBack"
                label="DNI del vendedor (trasero)"
                onChange={(e) => handleChange("sellerDniBack", e)}
                value={documentForm.sellerDniBack.value}
                isValid={documentForm.sellerDniBack.valid}
              />
            </div>
            <div className="w-full md:w-1/3 px-3"></div>
          </div>
        </div>

        <div className="w-full">
          <div className="w-full mb-4">
            <Typography className="text-left mb-2 form-contract-title">
              Documentación del
              <span className="color-blue-custom-text"> vehículo</span>
            </Typography>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <FileInputComponent
                name="techDataFront"
                label="Ficha técnica (frontal)"
                onChange={(e) => handleChange("techDataFront", e)}
                value={documentForm.techDataFront.value}
                isValid={documentForm.techDataFront.valid}
              />
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <FileInputComponent
                name="techDataBack"
                label="Ficha técnica (trasera)"
                onChange={(e) => handleChange("techDataBack", e)}
                value={documentForm.techDataBack.value}
                isValid={documentForm.techDataBack.valid}
              />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <FileInputComponent
                name="circulationPermit"
                label="Permiso de circulación"
                onChange={(e) => handleChange("circulationPermit", e)}
                value={documentForm.circulationPermit.value}
                isValid={documentForm.circulationPermit.valid}
              />
            </div>
          </div>
        </div>

        <div className="w-full">
          <div>
            <Typography className="text-left form-contract-title">
              Documentación
              <span className="color-blue-custom-text"> extra</span>
            </Typography>
          </div>
          <div className="flex -mx-3 mb-6">
            <section className="flex flex-col w-full">
              <p className="mt-2 ml-3 text-xs justify-start text-left color-blue-custom-text">
                Si es necesario también puedes subir aqui cualquier documento
                que consideres.
              </p>
              <div className="grid md:grid-cols-3 w-full mt-6">
                <div className={extraFiles.length >= 5 ? "-mt-4" : "mt-0"}>
                  <div>{extraFiles.length >= 5 && <MaxFiles />}</div>
                  <div className="px-3">
                    <MultifileInputComponent
                      name="extra"
                      onChange={(e) => handleMultifileInput("extra", e)}
                    />
                  </div>
                </div>
                {/* Renderizacion de componentes */}
                {extraFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex ml-7 md:ml-4 mt-6 w-5/6 max-h-12 text-left"
                  >
                    <label
                      htmlFor={`${file.value.name}FileInput`}
                      className="block text-xs font-bold mb-1 color-blue-custom-text"
                    ></label>
                    <div
                      className={`relative rounded-md p-3 flex justify-center items-center input-file-form text-xs
                ${
                  file.valid === undefined
                    ? ""
                    : file.valid
                    ? "valid-bg"
                    : "invalid-bg"
                }`}
                    >
                      <GrAttachment className="h-5 w-5 color-blue-custom-text absolute left-3" />
                      <div className="flex justify-start w-full">
                        <span className="text-left px-2 pl-8">
                          {file.value.name}
                        </span>
                        <AiOutlineClose
                          className="h-5 w-5 color-red-custom-text cursor-pointer"
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div>
                      <input
                        type="file"
                        id={`${file.value.name}FileInput`}
                        name={file.value.name}
                        className="sr-only"
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>

        <div className="w-full mb-8 mt-16">
          <ButtonUpload
            onChange={(e) => handleChange("contractDocument", e)}
            isFileValid={documentForm.contractDocument.valid}
          />

          <div className="flex items-center ml-4 mt-4">
            <input
              type="checkbox"
              onClick={(e) => {
                setTerms(e.target.checked);
              }}
            />
            <label className="ml-2 text-sm text-blue-700">
              Acepto los{" "}
              <a
                className="underline text-blue-700"
                href="https://herreraconsultores.net/politica-privacidad/"
              >
                Términos y condiciones del servicio
              </a>
            </label>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between self-center md:flex-wrap -mx-3 mb-6">
          <div className="flex justify-center w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <ButtonBefore onPrevious={onPreviousTab} />
          </div>
          <div className="flex justify-center w-full md:w-1/3 px-3">
            <ButtonFinish onFinish={handleChangeFinish} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form4Component;
