import React from "react";

export default function PaymentMethodHeader() {
  return (
    <>
      <div id="redirect-top" class="flex flex-col mt-32 mb-10">
        <div class="flex flex-col md:flex-row pt-5 w-4/5 self-center">
          <div class="flex w-full flex-col md:flex-row">
            <p className="flex self-center md:self-start md:w-1/4">
              Herrera Consultores
            </p>
            <div className="border-b-2 border-solid self-center border-black w-full md:w-1/4"></div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-5 w-4/5 justify-between self-center">
          <div class="text-6xl font-bold">
            <h1>Proceso de pago</h1>
          </div>
          <div class="my-5">
            <p class="mb-5 text-gray-600 text-sm">Contacta en el</p>
            <a
              class="border border-solid py-4 px-20 border-herrera-orange rounded-full text-herrera-orange"
              href="tel:954815846"
            >
              954 81 58 46
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-5 w-4/5 justify-between self-center">
          <p className="flex text-gray-600 text-justify">
            Recibirás un mail con la confirmación de los datos y una copia del
            contrato de compra y venta para rellenar: Además nos pondremos en
            contacto contigo en la mayor brevedad posible para terminar de
            tramitar la gestión.
          </p>
        </div>
      </div>
    </>
  );
}
