import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CompletedFormComponent from "../CompletedFormComponent";
import { useAppNavigation } from "../../utils/navigations";

const PaymentResult = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const estado = queryParams.get("estado");
  const { navigateTo } = useAppNavigation();

  useEffect(() => {
    if (estado === "0") {
      navigateTo("/", { state: { showBanner: true } }); // Redirige con el estado del banner
    }
    // No redirige si el estado no es "0"
  }, [estado, navigateTo]);

  return (
    <div>
      <CompletedFormComponent
        goBack={() => {
          localStorage.clear();
          navigateTo("/", { state: { showBanner: false } });
        }}
      />
    </div>
  );
};

export default PaymentResult;
